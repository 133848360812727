import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import L from 'leaflet';
const defaultIcon = L.icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
});

const InterviewLocation = ({ latitude, longitude, mapData }) => {

    // console.log(mapData?.A === undefined)
    return (
        <div>
            {
                mapData?.A?.Latitude === null && mapData?.A?.Longitude === null && mapData?.B?.Latitude === null && mapData?.B?.Longitude === null && mapData?.C?.Latitude === null && mapData?.C?.Longitude === null ?
                    "" :
                    <MapContainer center={[mapData?.A?.Latitude === null ? latitude : mapData?.A?.Latitude, mapData?.A?.Longitude === null ? longitude : mapData?.A?.Longitude]} zoom={13} scrollWheelZoom={false} style={{ height: "300px", width: "100%" }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />


                        {mapData?.A?.Latitude === null && mapData?.A?.Longitude === null || mapData?.A?.Latitude === undefined && mapData?.A?.Longitude === undefined ? "" :
                            <Marker position={[mapData?.A?.Latitude, mapData?.A?.Longitude]} icon={defaultIcon}>
                                <Popup className='text-center'>
                                    A
                                </Popup>
                            </Marker>
                        }
                        {mapData?.B?.Latitude === null && mapData?.B?.Longitude === null || mapData?.B?.Latitude === undefined && mapData?.B?.Longitude === undefined ? "" :
                            <Marker position={[mapData?.B?.Latitude, mapData?.B?.Longitude]} icon={defaultIcon}>
                                <Popup className='text-center'>
                                    B
                                </Popup>
                            </Marker>
                        }
                        {mapData?.C?.Latitude === null && mapData?.C?.Longitude === null || mapData?.C?.Latitude === undefined && mapData?.C?.Longitude === undefined ? "" :
                            <Marker position={[mapData?.C?.Latitude, mapData?.C?.Longitude]} icon={defaultIcon}>
                                <Popup className='text-center'>
                                    C
                                </Popup>
                            </Marker>
                        }
                    </MapContainer>}

        </div>
    )
}

export default InterviewLocation