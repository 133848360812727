import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    value:[],
  }
export const OtpTimer = createSlice({
    name: 'OtpTime',
    initialState,
    reducers: {
      setTime: (state, action) => {
        state.value = action.payload;
      },
      clearTime: (state) => {
        state.value = [];
      }
     
    },
  })

export const { setTime,clearTime } = OtpTimer.actions

export default OtpTimer.reducer