import React, { useState, useRef, useEffect } from 'react';
import { Col, Row,OverlayTrigger,Tooltip } from 'react-bootstrap'
import VideoOff from '../../../assets/img/capture.png'
import { useInView } from 'react-intersection-observer';
import './interviewpage.css'
import { postRequest, postFormData } from '../../Services/api'
import { toast } from 'react-toastify';
function ImageCapture({ setImageCaptureApi, nextButtonShow, setImageSrc, imgaeCaptureApi, isChecked, setCheckBoxValidate, profileId }) {
    const [locationPermission, setLocationPermission] = useState(null);
    const [cameraPermission, setCameraPermission] = useState(null);
    const [microphonePermission, setMicrophonePermission] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingApi, setIsLoadingAPi] = useState(false);
    const [cameraStatus, setCameraStatus] = useState("");
    // console.log(locationPermission)
    // console.log(cameraPermission)
    // console.log(microphonePermission)
    const videoRef = useRef();
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    let imageSrcApi = null
    useEffect(() => {
        const checkPermissions = async () => {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocationPermission('granted');
                        },
                        (error) => {
                            setLocationPermission('denied');
                        }
                    );
                } else {
                    setLocationPermission('unsupported');
                }

                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                    setCameraPermission('granted');
                    setMicrophonePermission('granted');
                }
            } catch (error) {
                console.error('Error requesting permissions:', error);
                setCameraPermission('denied');
                setMicrophonePermission('denied');
            }
        };

        checkPermissions();
    }, []);
    useEffect(() => {
        if (inView && !isPlaying) {
            navigator?.mediaDevices?.getUserMedia({ video: true })
                .then((stream) => {
                    const video = videoRef.current;
                    video.srcObject = stream;
                    setIsPlaying(true);
                })
                .catch((error) => {
                    setCameraStatus(error)
                });
        }
    }, [inView, isPlaying]);
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const handleImageApi = async () => {

        const data = new FormData();
        data.append('ProfileId', profileId);
        data.append('Picture', imageSrcApi);
        setIsLoadingAPi(true);

        await postFormData('api/Interview/InsertInterviewDocuments', data).then((res) => {
            var result = JSON.parse(res?.data?.Status);
            // setImageCaptureApi(result);
            if (result) {
                setIsLoadingAPi(false);
            }
        });


    }
     
   const handleCapture = () => {

        if (cameraPermission === "granted" && microphonePermission === "granted") {
            if (isChecked) {
                setImageCaptureApi(true);
                const video = videoRef.current;
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth / 2;  // Set canvas width to half of video width
                canvas.height = video.videoHeight / 2;  // Set canvas height to half of video height
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

                // Reduce quality to 0.3 to compress the JPEG image
                const dataUrl = canvas.toDataURL('image/jpeg', 0.9);
                // console.log(dataUrl)

                // Convert dataUrl to file object
                const blob = dataURItoBlob(dataUrl);
                const file = new File([blob], 'capture.jpeg', { type: 'image/jpeg' });

                // Set the image source to the dataUrl
                if (file != "") {
                    // imageSrcApi = file
                    setImageSrc(dataUrl);
                    // console.log(imageSrcApi)
                }
            }
            else {
                setCheckBoxValidate(true)
            }
        }
        else {
            toast.error("please allow permission for location, microphone and camera")
        }


    };

    // console.log(isLoadingApi)
    return (
        <Col lg={3} md={12} className="mt-sm-cus-10">
            <div>
                <div className="video-container" ref={ref}>

                    <div className={`lazy-loading ${isLoading || isLoadingApi ? 'is-loading' : ''}`}>
                        <div className="spinner"></div>
                    </div>
                    <video
                        ref={videoRef}
                        onCanPlay={() => {
                            setIsPlaying(true);
                            setIsLoading(false);
                        }}
                        playsInline
                        muted
                        autoPlay
                        loop
                        className={inView ? 'is-visible' : ''}
                    />
                </div>
                {/* <button >Capture</button>
                {imageSrc && <img src={imageSrc} />} */}
                {/* {imageSrc && <img src={imageSrc} alt="Captured Image" />} */}
            </div>
            <Row>
                <Col>
                    <div className='d-flex justify-content-center mt-2 gap-3'>
                        {/* {!isLoading ?
                            <div className={`lazy-loading ${isLoading ? 'is-loading' : ''}`}>
                                <div className="spinner"></div>
                            </div> : */}

                        <figure className='text-center'>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip4">Click here</Tooltip>}
                            >
                                <span>
                                    <img src={VideoOff} onClick={() => { handleCapture(); }} className='img-size-audio' />
                                </span>
                            </OverlayTrigger>
                            <figcaption className='caption-for-img'>Capture</figcaption>
                        </figure>
                        {/* } */}

                    </div>
                </Col>
            </Row>
        </Col>

    );
}

export default ImageCapture