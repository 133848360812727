import React, { useEffect } from 'react'

const CountDown = ({ hours = 0, minutes = 0, seconds = 0, setOver,over,resetOTP }) => {
  
  useEffect(()=>{
 if(resetOTP=== true || resetOTP === false){
    
      setTime({
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds)
      });
      setOver(false);
  
  }
  },[resetOTP])

    const [paused, setPaused] = React.useState(false);
  const [time, setTime] = React.useState({
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseInt(seconds)
  });

  const tick = () => {
    if (paused || over) return;
    if (time.hours == 0 && time.minutes == 0 && time.seconds == 0)
      setOver(true);
    else if (time.minutes == 0 && time.seconds == 0)
      setTime({
        hours: time.hours - 1,
        minutes: 59,
        seconds: 59
      });
    else if (time.seconds == 0)
      setTime({
        hours: time.hours,
        minutes: time.minutes - 1,
        seconds: 59
      });
    else
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1
      });
  };

 
  // const reset = 

  React.useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });
    return (
        <div>
            <div>
                <p>{over?"":`${time.minutes
                    .toString()
                    .padStart(2, "0")}:${time.seconds.toString().padStart(2, "0")} minutes`} &nbsp;  {over ? "Code has expired" : ""}</p>
                <div></div>
              

            </div>
        </div>
    )
}

export default CountDown