import React, { useState } from "react";
const Search = ({ onSearch }) => {
  const [search, setSearch] = useState("");
  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  return (
    <input
      type="text"
      style={{ width: "240px" }}
      placeholder="Search"
      value={search}
      className="search-field-table form-control"
      onChange={(e) => onInputChange(e.target.value)}
    />
  );
};

export default Search;
