import React, { useState, useEffect } from "react";

const QuestionTimer = ({ questions, duration,totalTime,setTotalTime, currentQuestionIndex,setCurrentQuestionIndex,timeLeft,setTimeLeft }) => {
  
  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  // const [timeLeft, setTimeLeft] = useState(duration);
  
 

  useEffect(() => {
    setTotalTime(questions.length * duration);
  }, [duration, questions]);
  useEffect(() => {
    if (timeLeft === 0) {
      console.log("check")
    }
  }, [currentQuestionIndex, timeLeft]);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (currentQuestionIndex === questions.length - 1) {
  //       clearInterval(interval);
  //       setCountdownFinished(true);
  //     } else {
  //       setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  //       setTimeLeft(duration);
  //     }
  //   }, duration * 1000);
  //   return () => clearInterval(interval);
  // }, [currentQuestionIndex, duration, questions]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const progress = ((currentQuestionIndex * duration + (duration - timeLeft)) / totalTime) * 100;

  const minutesLeft = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft % 60;

  const minutesTotal = Math.floor(totalTime / 60);
  const secondsTotal = totalTime % 60;

  return (
    <div className="py-2 px-2">
      {/* countdownFinished ? (
        <h1>Thank you!</h1>
      ) : */}
      { questions.length > 0 ? (
        <>
          <h1 className="question-font-cus">{questions[currentQuestionIndex].Questions}</h1>
          {/* <p>
            Time Left:{" "}
            {timeLeft >= 0
              ? `${minutesLeft}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`
              : "0:00"}
          </p>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <p>
            Total Time: {minutesTotal}:{secondsTotal < 10 ? "0" : ""}
            {secondsTotal}
          </p> */}
        </>
      ) : (
        <h1>No questions available</h1>
      )}
    </div>
  );
};

export default QuestionTimer;
