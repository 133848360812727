import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Proof from '../../assets/img/proof.jpg'

const ProofIdImgView = (props) => {
    const { show, onClose, imgView, loading,imgProof } = props;

    return (
        <div className='image-view'>
            <Modal className='img-modal'
                show={show} onHide={onClose}
                dialogClassName="modal-lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <img src={imgProof} className='img-fluid w-100' />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProofIdImgView