import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useMemo } from 'react'
import { Pagination } from 'react-bootstrap'
import { FaAccessibleIcon, FaImages, FaClipboard, FaEnvelope, FaRegEdit, FaEye } from "react-icons/fa";
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import imgViewIcon from '../../assets/img/imgView.svg'
import { postFormData, postRequest, USERID,postRequestOffer } from '../Services/api';
import Moment from 'moment';
import PaginationComponent from './Pagination';
import Search from './Search';
import _, { truncate } from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import ImageViewer from '../ModalPopup/ImageViewer'

const InterviewListTable = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const [filterDataList,setFilterDataList] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sharedProfileList, getSharedProfileList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [selectItemsPerPage, setselectItemsPerPage] = useState(10);
    const [offerStatus, setOfferStatus] = useState()
    const [showImg, setShowImg] = useState(false);
    const [imgView, setImageView] = useState()
    const handleShowImg = async (profileId) => {
        setShowImg(true);
        setImageView("")
        await postRequest('api/Profile/GetDocumentView?ProfileId=' + profileId).then((res) => {
            setImageView('data:application/pdf;base64,' + JSON.parse(res?.data?.Data))
            setLoading(false);
        });

    }
    const handleCloseImg = () => setShowImg(false);
    const handleChangeItem = (e) => {
        setselectItemsPerPage(e.target.value);
        setCurrentPage(1);
    }
    const handleStatusOnChange = (event, profileId) => {

    }
    useEffect(() => {
        setTotalItems(sharedProfileList.length);

    }, [sharedProfileList, offerStatus])

    let filteredData = useMemo(() => {
        let filterData = sharedProfileList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
        setTotalItems(filterData.length);
        return filterData
    }, [search, selectItemsPerPage, currentPage, sharedProfileList]);

    const [offerStatusChange, setOfferStatusChange] = useState()
    useEffect(() => {
        getSharedProfile();
        setCurrentPage(1);
        setOfferStatusChange()
    }, []);



    useImperativeHandle(ref, () => ({
        getSharedProfileChild() {
            getSharedProfile();
        },
    }));
    let isLoggedIn = JSON.parse(localStorage.getItem('token'))
    const getSharedProfile = async () => {
        setLoading(truncate);
        var res = await postRequest(`api/ScheduleInterview/GetRecentInterviewSchedule?CompanyId=${isLoggedIn?.CompanyId}`);
        var result = JSON.parse(res?.data?.Data);
        result.map((item) => {
            item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
        });
        getSharedProfileList(result);
        setLoading(false);
    }

    const lastSessionNumber = currentPage * selectItemsPerPage;
    const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
    const limitedSessions = filteredData.slice(
        firstSessionIndex,
        lastSessionNumber
    );
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const handleResendLink = async (ProfileId, Email, CompanyId, CategoryId, SubCategoryId) => {

        const data = new FormData()
        data.append('CategoryId', CategoryId)
        data.append('ProfileId', ProfileId)
        data.append('CompanyId', isLoggedIn?.CompanyId)
        data.append('SubCategoryId', SubCategoryId == null ? "" : SubCategoryId)
        await postFormData('api/Interview/ResendRandomQuestion', data).then((res) => {
            let error = JSON.parse(res?.data?.Data)
            toast.error(error[0].ErrorMessage)
            if (error[0].ErrorMessage == "" || error.length > 1) {
                postRequest('/api/Interview/GenerateInterviewLink?ProfileId=' + ProfileId + '&Email=' + Email + '&CompanyId=' + isLoggedIn?.CompanyId).then((res) => {
                    if (res?.data.Status) {
                        if (res?.data?.SuccessMessage === "Attempt over the three times") {
                            toast.error(res?.data?.SuccessMessage);
                        }
                        else if (res?.data?.SuccessMessage === "Cannot resend the mail in InterviewLevel - 2") {
                            toast.error(res?.data?.SuccessMessage);
                        }
                        else {
                            toast.success('Interview link resend successfully');
                        }

                    }
                    else {
                        toast.error(res?.data.ErrorMessage)
                    }
                })
            }

        })

        setIsButtonDisabled(true);

        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 5000);

    };
    // console.log(limitedSessions);
    return (
        <div>
            <div>

                <div >
                    <Container fluid >
                        <Row>
                            <Col className='bg-white mb-3'>
                                <div className='searched-table-heading'>
                                    <h3>Recently Interview profile</h3>
                                </div>
                                <div className='search-filter-box-custom'>
                                    <div className='searched-table-heading mt-3 mb-2'>
                                        <Search
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="wrap-select-arrow mt-3 mb-2">
                                            <select className="form-control sorting-select " onChange={handleChangeItem}>
                                                <option value={10} selected="selected">10 Items per page</option>
                                                <option value={25}>25 Items per page</option>
                                                <option value={50}>50 Items per page</option>
                                                <option value={100}>100 Items per page</option>
                                            </select>
                                            <div className="select-arrow">
                                                <div className="arrow-up"></div>
                                                <div className="arrow-down"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=" wrapper">
                                    <Table striped responsive borderless className='  searched-table search-table inner share-table'>

                                        <tr>
                                            <th >shared on</th>
                                            <th>Candidate name</th>
                                            <th>Email</th>
                                            <th>mobile number</th>
                                            <th>job title</th>
                                            <th>Status</th>
                                            <th colSpan={3}>action</th>
                                        </tr>

                                        <tbody>
                                            {loading ?
                                                <tr>
                                                    <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden text-center">Loading...</span>
                                                        </Spinner>
                                                    </div></td>
                                                </tr>
                                                :
                                                limitedSessions.length == 0 ?
                                                    <tr>
                                                        <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                                    </tr> :
                                                    limitedSessions.map((item, index) => {

                                                        return (
                                                            <tr>
                                                                <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')}</td>
                                                                <td> {item.CandidateName}</td>
                                                                <td className='line-break-cus'>{item.Email}</td>
                                                                <td>{item.PhoneNumber}</td>
                                                                <td>{item?.JobTitle == "null" || item?.JobTitle == "undefined" ? "" : item.JobTitle}</td>

                                                                <td className='text-capitalize'>
                                                                    {
                                                                        item.Status == "interview" || item.Status == "Interview" ?
                                                                            <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'
                                                                                onChange={e => {
                                                                                    postRequestOffer('api/Profile/AccountEnable?profileId=' + item.ProfileId + '&status=' + e.target.value + '&isextrenal=false').then((res) => {
                                                                                        var result = JSON.parse(res?.data?.Data);
                                                                                        setOfferStatus(result)
                                                                                        getSharedProfile();
                                                                                        if (result?.Status == "pending" || result?.Status == "Pending") {
                                                                                            toast.success("Offer shared successfully");
                                                                                        }
                                                                                        else {
                                                                                            toast.success("Status updated successfully");
                                                                                        }


                                                                                    })
                                                                                    postRequest('/api/Notification/OfferNotificationUpdate?ProfileId=' + item.ProfileId + '&status=' + e.target.value).then((res) => {
                                                                                        setOfferStatusChange(res.data)


                                                                                    })
                                                                                }
                                                                                }
                                                                            >
                                                                                {/* <option value="" selected >--Select--</option> */}
                                                                                <option value="Interview">Interview</option>
                                                                                <option value="pending">Share Offer</option>
                                                                            </select>
                                                                            :
                                                                            item.Status == "pending" ?
                                                                                item.Status
                                                                                : item.Status
                                                                        // :
                                                                        // item.Status == "Interview" ?
                                                                        //   <select className='offered-select-option' value={item.Status.toLowerCase()} id='status'>
                                                                        //     <option value="Interview">Interview</option>
                                                                        //   </select>
                                                                        //   : <select className='offered-select-option' value={item.Status.toLowerCase()} id='status' onChange={e => {
                                                                        //     postRequest('api/Profile/StatusUpdate?profileId=' + item.ProfileId + '&status=' + e.target.value + '&isextrenal=false').then((res) => {
                                                                        //       var result = JSON.parse(res?.data?.Data);
                                                                        //       setOfferStatus(result)
                                                                        //     })
                                                                        //     postRequest('/api/Notification/OfferNotificationUpdate?ProfileId=' + item.ProfileId + '&status=' + e.target.value).then((res) => {
                                                                        //       setOfferStatusChange(res.data)
                                                                        //       toast.success("Status updated successfully");
                                                                        //       getSharedProfile();
                                                                        //     })
                                                                        //   }
                                                                        //   }>
                                                                        //     {/* <option value="Interview">Interview</option> */}
                                                                        //     <option value="offered">Offered</option>
                                                                        //     <option value="onboard">Onboard</option>
                                                                        //     <option value="revoke">Revoke</option>
                                                                        //   </select>
                                                                    }
                                                                </td>

                                                                <td >
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        {
                                                                            item.Status == "declined" || item.Status == "revoke" || item.Status == "offered" || item.Status == "onboard" || item.Status == "Offered" || item.Status == "pending" || item.Status == "Pending"
                                                                                ?
                                                                                <div style={{ display: "flex", gap: "10px" }}>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip4">View</Tooltip>}
                                                                                    ><span>
                                                                                            <Link to={`/schedule-interview/interview-profile-view/${item.ProfileId}`}><FaEye className='intericon-color' size={18} disabled={isButtonDisabled} /></Link>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                                :

                                                                                <div style={{ display: "flex", gap: "10px" }}>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip4">Resend Link</Tooltip>}
                                                                                    >
                                                                                        <span>
                                                                                            <FaEnvelope
                                                                                                className={`intericon-color ${isButtonDisabled ? 'disabled' : ''}`}
                                                                                                onClick={() => {
                                                                                                    if (!isButtonDisabled) {
                                                                                                        setIsButtonDisabled(true);
                                                                                                        setTimeout(() => {
                                                                                                            setIsButtonDisabled(false);
                                                                                                        }, 5000);
                                                                                                        handleResendLink(item.ProfileId, item.Email, item.CompanyId, item.CategoryId, item.SubCategoryId);
                                                                                                    }
                                                                                                }}
                                                                                                size={18}
                                                                                            />
                                                                                        </span>
                                                                                    </OverlayTrigger>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip4">Edit</Tooltip>}
                                                                                    ><span>
                                                                                            <Link to={`/schedule-interview/${item.ProfileId}&&isStatusEdit=${true}`}> <FaRegEdit className='intericon-color' size={18} /></Link>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip4">View</Tooltip>}
                                                                                    ><span>
                                                                                            <Link to={`/schedule-interview/interview-profile-view/${item.ProfileId}`}><FaEye className='intericon-color' size={18} /></Link>
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                        }



                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </Table>
                                </div>


                                <div className='pagination-box '>
                                    <div className='pagination-showing-count-status'>
                                        {limitedSessions.length == 0 ? " " : <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems} </p>}
                                    </div>
                                    <div>

                                        <PaginationComponent
                                            itemsCount={totalItems}
                                            itemsPerPage={selectItemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}

                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div>

                </div>
            </div>
            <ModalOfferDetails show={show} onClose={handleClose} />
            <ImageViewer imgView={imgView} loading={loading} showImg={showImg} onCloseImg={handleCloseImg} />
        </div >
    )
});

export default InterviewListTable