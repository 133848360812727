import React, { useEffect, useRef, useState } from 'react';
import {
    FaBars,
    FaRegBell
    , FaUserCircle
} from "react-icons/fa";
import { NavLink, useLocation } from 'react-router-dom';
import Logo from "../../assets/img/logo.png"
import Profilesqu from "../../assets/img/profile.png"
import smallLogo from "../../assets/img/rlogo.png"
import 'bootstrap/dist/css/bootstrap.min.css';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { FaSortDown, FaUserEdit } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faHouse, faShareFromSquare, faHeadset, faRightFromBracket, faMedal, faUser, faUserAlt, faClipboard, faDesktop } from '@fortawesome/free-solid-svg-icons'
import Profile from '../../assets/img/client-img.png';
import { postRequest } from '../Services/api';
import { Link, useNavigate } from 'react-router-dom';
import "./sidebar.css"
import { clearToken } from '../../app/slice/userAuthenticate'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import userImg from '../../assets/img/userimg.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIdleTimer } from 'react-idle-timer'
import Feedback from '../ModalPopup/FeedBack';

const SideNavbar = ({ children }) => {
    let location = useLocation()
    
    let navigate = useNavigate()
    let tokens = useSelector((state) => state.userAuth.value);
    let LocalToken = JSON.parse(localStorage.getItem('token'))
    let rollId = LocalToken?.RollId;
    useEffect(() => {
        // setAuthToken(tokens)
        tokens = tokens;


    }, [tokens, rollId]);

    useEffect(() => {
        if (rollId == 1) {
            // return navigate('/', { replace: true })
        }

    }, [rollId])

    let idelTimerRef = useRef(null)

    let companyId = LocalToken?.CompanyId

    const [isOpen, setIsOpen] = useState(true);
    const dispatch = useDispatch();
    const toggle = () => setIsOpen(!isOpen);
    const togglemob = () => setIsOpen(!isOpen);
    const menuItem = rollId == 1 ? [
        {
            path: "/",
            name: "Dashboard",
            icon: <FontAwesomeIcon icon={faHouse} />
        },
        {
            path: "/invite",
            name: "Invite",
            icon: <FontAwesomeIcon icon={faCompass} />
        }

    ] : rollId == 2 ? [
        {
            path: "/",
            name: "Dashboard",
            icon: <FontAwesomeIcon icon={faHouse} />
        },
        {
            path: "/searchprofile",
            name: "Search Profile",
            icon: <FontAwesomeIcon icon={faSearch} />
        },
        {
            path: "/shareprofile",
            name: "Share Profile",
            icon: <FontAwesomeIcon icon={faShareFromSquare} />
        },
        {
            path: "/schedule-interview",
            name: "Schedule Interviews",
            icon: <FontAwesomeIcon icon={faClipboard} />
        },
        {
            path: "/questionnaires",
            name: "Questionnaires",
            icon: <FontAwesomeIcon icon={faDesktop} />
        },
        {
            path: "/usermanagement",
            name: "User Management",
            icon: <FontAwesomeIcon icon={faUserAlt} />
        }
    ] : [
        {
            path: "/",
            name: "Dashboard",
            icon: <FontAwesomeIcon icon={faHouse} />
        },
        {
            path: "/searchprofile",
            name: "Search Profile",
            icon: <FontAwesomeIcon icon={faSearch} />
        },
        {
            path: "/shareprofile",
            name: "Share Profile",
            icon: <FontAwesomeIcon icon={faShareFromSquare} />
        },
        {
            path: "/schedule-interview",
            name: "Schedule Interviews",
            icon: <FontAwesomeIcon icon={faClipboard} />
        },
        {
            path: "/questionnaires",
            name: "Questionnaires",
            icon: <FontAwesomeIcon icon={faDesktop} />
        },

    ]
    const [showA, setShowA] = useState("");
    const toggleShowA = () => setShowA(!showA);
    const [showB, setShowB] = useState("");
    const toggleShowB = () => setShowB(!showB);
    const [expanded, setExpanded] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationList, getNotificationList] = useState([]);
    const [notificationCountList, getNotificationCountList] = useState([]);
    const [countNotifi, setCountViewNotifi] = useState()

    function expand() {
        setExpanded(true);
    }
    const notify = async () => {
        setNotification(true);
        var res = await postRequest('/api/Notification/GetNotification');
        getNotificationList(JSON.parse(res?.data?.Data));
        postRequest('/api/Notification/GetNotificationUpdate').then((res) => {
            // console.log()
            getNotificationCount()
            setCountViewNotifi(res?.data?.Status)
            if (countNotifi === true) {
                getNotificationCount();
                getNotificationCountList()

            }
        });




    }
    useEffect(() => {
        getNotificationCount();

        // if (countNotifi === true) {
        // }
        // else {

        // }
    }, [])

    // console.log(notificationList)
    function notifyClose() {
        setExpanded(false);
    }


    useEffect(() => {
        let handlerNotification = (event) => {
            if (!notifyRef.current.contains(event.target)) {
                setNotification(!notification)
            }
        }
        document.addEventListener('mousedown', handlerNotification);
        return () => {
            document.removeEventListener('mousedown', handlerNotification)
        }
    });

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setExpanded(!expanded)
            }
        }
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    });
    let menuRef = useRef();
    let notifyRef = useRef();


    const [dashboardList, getDashboardList] = useState([]);
    const [selectedImg, setSelectedImg] = useState();

    useEffect(() => {
        getDashboard();
        // getNotification();
    }, [Profilesqu, userImg]);
    const getDashboard = async () => {
        var res = await postRequest('api/Profile/GetDashboard');
        getDashboardList(JSON.parse(res?.data?.Data));
        postRequest('/api/Verification/GetMyProfile').then((profileData) => {
            setSelectedImg('data:image/jpeg;base64,' + JSON.parse(profileData?.data?.Data).ProfileImage);
        });
    }


    let companyName = '';
    if (dashboardList != null && dashboardList.CompanyData) {
        companyName = dashboardList.CompanyData.CompanyName;
    }
    else {
        companyName = '';
    }
    const logout = () => {

        localStorage.removeItem("token")
        localStorage.removeItem("setupTime")
        sessionStorage.clear()
        dispatch(clearToken())
        // var res = postRequest('/api/Notification/GetNotificationUpdate');

        // getNotificationList(JSON.parse(res?.data?.Data));
    }
    const mobileScreen = useMediaQuery('(max-width:800px)');
    useEffect(() => {
        if (mobileScreen) {
            // you don't need return statement here.
            setIsOpen(false);
            togglemob();
        }
    }, [mobileScreen])


    const timeout = 1000 * 60 * 30;
    const [isIdle, setIsIdle] = useState(false);
    const handleOnActive = () => setIsIdle(false);
    const handleOnIdle = () => setIsIdle(true);

    const [remaining, setRemaining] = useState(timeout);
    useEffect(() => {
        // logout()
        let sessionValidate = JSON.parse(sessionStorage.getItem('Data'))
        // if (sessionValidate == null) {
        //     localStorage.removeItem("token")
        // }
        setRemaining(getRemainingTime());
    }, [])
    const getNotificationCount = async () => {
        var res = await postRequest(`/api/Notification/NotificationCount?companyid=${companyId}`)
        getNotificationCountList(JSON.parse(res?.data?.Data))



    }
    useEffect(() => {
        // setTimeout(() => {
        // logout()
        // }, 1000 * 60 * 1);
        if (isIdle === true) {
            logout()
        }

    }, [isIdle, remaining]);
    // const onAction = e => {
    //     console.log("user did something", e);
    //   };

    //   const onActive = e => {
    //     console.log("user is active", e);
    //     // console.log("time remaining", this.idleTimer.getRemainingTime());
    //   };

    //   const onIdle = e => {
    //     console.log("user is idle", e);
    //     // console.log("last active", this.idleTimer.getLastActiveTime());
    //   };
    var hours = 4; // to clear the localStorage after 1 hour
    // (if someone want to clear after 8hrs simply change hours=8)
    var now = new Date().getTime();
    useEffect(() => {

        var setupTime = localStorage.getItem('setupTime');
        if (setupTime == null) {
            localStorage.setItem('setupTime', now)
        } else {
            if (now - setupTime > hours * 1000 * 60 * 60) {
                localStorage.clear()
                logout()
            }
        }
    }, [now])


    const {
        reset,
        pause,
        resume,
        getRemainingTime,
        getLastActiveTime,
        getElapsedTime
    } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
        crossTab: true
    });
    const closeNoti = () => {
        var res = postRequest('/api/Notification/GetNotificationUpdate');

    }
    const [show, setShow] = useState(false);
    const handleShow = (email, candidateName) => {
        setShow(true);

    }
    const handleClose = () => setShow(false);

    return (
        <div className=''>
            <div className='padding-left-custom-header bg-white container-fluid d-flex justify-content-between align-items-center main-header shadow-sm'>
                <div className="top_section "  >
                    <img src={isOpen ? Logo : smallLogo} className="img-fluid trasition-logo mob-none" />
                    <div className={` ${isOpen ? 'bars' : "mobile-bar"}`}>
                        <FaBars onClick={toggle} style={{ color: "#000" }} className="position-toggle" />
                    </div>
                </div>

                <div className='header-notification-profile mui-overide-badge'>
                    {notificationCountList == 0 ? <FaRegBell onClick={notify} style={{ fontSize: "24px", marginLeft: "-150px", marginTop: "5px", cursor: "pointer" }} > </FaRegBell> : <Badge style={{ fontSize: "24px", marginLeft: "-150px", marginTop: "5px", cursor: "pointer" }} badgeContent={notificationCountList} color="primary" onClick={notify}>
                        <FaRegBell > </FaRegBell>
                        {/* <createMocks
                            // ref={ref => { Null = ref }}
                            element={document}
                            onActive={onActive}
                            onIdle={onIdle}
                            onAction={onAction}
                            debounce={250}
                            timeout={10}
                        /> */}

                    </Badge>}
                    {
                        notification &&
                        <div class="toast-notification">
                            <button type="button" class="btn-close btn-close-notify" onClick={closeNoti} aria-label="Close" data-dismiss="toast"></button>
                            <div ref={notifyRef}>
                                <div class="toast-header" >

                                    <strong class="me-auto">Notification</strong>

                                </div>
                                <div className={`${notificationList.length > 0 ? "notification-box-cus" : "notification-box-cus-he"}`}>
                                    {notificationList.length > 0 ?
                                        notificationList.map((item, index) => {
                                            return (
                                                <div key={index} class="toast-body border-bottom-1"><span className={`${item.IsViewed ? "notification-font-bold" : "notification-font"}`}>{item.NotificationMessage}</span></div>
                                            )

                                        }) : <div class="toast-body text-center border-bottom-1">No notifications yet!</div>
                                    }
                                </div>


                            </div>
                        </div>
                    }
                    <div>

                        <div className='dashboard-user-profile' onClick={expand} >
                            <img src={selectedImg == "data:image/jpeg;base64,undefined" || selectedImg == "" ? userImg : selectedImg} className="profile-img-navbar" />
                            <p>
                                {rollId == 1 ? LocalToken?.CompanyName : companyName}
                            </p>
                            <FaSortDown className='icon-profile-dropdown' />

                        </div>
                        {
                            expanded && <div className='toast-profile' ref={menuRef} >
                                <div className=' profile-img-text'>
                                    <Avatar className='bg-color-profile-user' variant="square">
                                        <img src={selectedImg == "data:image/jpeg;base64,undefined" || selectedImg == "" ? userImg : selectedImg} className="profile-img-avatar" />
                                    </Avatar>
                                    <h1> {rollId == 1 ? LocalToken?.CompanyName : companyName}</h1>
                                </div>
                                <div className='mt-2 mb-2'>
                                    <div className='gap-profile pt-2 pb-2'><Link to="/myprofile" className="myprofile-link"><FaUserCircle className='icon-profile' style={{width:"20px"}}/><span> &nbsp; My Profile</span></Link></div>
                                    {rollId !== 1 ?  <div className='gap-profile pt-2 pb-2' onClick={() => handleShow()}><Link to={location?.pathname} className="myprofile-link"><FaUserEdit className='icon-profile'  style={{width:"20px"}}/><span> &nbsp; Feedback</span></Link></div> : "" }
                                    <div className='gap-profile pt-2' ><FontAwesomeIcon className='icon-profile' icon={faRightFromBracket} onClick={logout} style={{width:"15px"}}/><span onClick={logout}>&nbsp; Logout</span></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="container-fluid padding-left-right-0 d-flex " >
                <div className={` ${isOpen ? 'sidebar' : "mobile-sidebar"} ${"desktop-menu "} `} >

                    {
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className="link" activeclassName="active">
                                <div className="icon" style={{width:"20px"}}>{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        ))
                    }
                </div>
                <div className={` ${isOpen ? 'sidebar' : "mobile-sidebar"} ${"mobile-menu-hide"}`} >

                    {
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} onClick={togglemob}  style={{ display: isOpen ? "inline-flex" : "none", width: "250px" }} className="link" activeclassName="active">
                                <div className="icon" onClick={togglemob} style={{width:"20px"}}>{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} onClick={togglemob} className="link_text">{item.name}</div>
                            </NavLink>
                        ))
                    }
                </div>
                <main className='bg-dashboard'>{children}</main>
            </div>
            <Feedback  show={show} onClose={handleClose} />
        </div>
    )
}

export default SideNavbar