import React, { useEffect, useState } from 'react'
import { Col, Container, Row, OverlayTrigger, Tooltip, Modal, Alert } from 'react-bootstrap'
import { FaAddressCard, FaUserAlt, FaPhoneAlt, FaEnvelope, FaInfoCircle, FaTimesCircle, FaUsers, FaRegCheckCircle, FaKey, FaCity, FaBuilding, FaPen, FaEyeSlash, FaEye } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchoolFlag } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { postRequest, postFormData } from '../Services/api';
import { FaChevronRight } from 'react-icons/fa'
import { toast } from 'react-toastify';
import SideNavbar from '../Dashboard/SideNavbar';
import OtpInput from "react-otp-input";
import { postVerify } from '../Services/api';
import { set } from 'lodash';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CountDown from '../SignUp/CountDown'
import Footer from '../Dashboard/Footer';
import userImg from '../../assets/img/userimg.png'
import { FaTimes } from 'react-icons/fa'

const MyProfile = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [iconChange, setIconChange] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [iconChange1, setIconChange1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [iconChange2, setIconChange2] = useState(false);
    const iconToggle = () => {
        setIconChange(!iconChange);
        setPasswordShown(!passwordShown);
        debugger;
    }
    const iconToggle1 = () => {
        setIconChange1(!iconChange1);
        setPasswordShown1(!passwordShown1);
    }
    const iconToggle2 = () => {
        setIconChange2(!iconChange2);
        setPasswordShown2(!passwordShown2);
    }
    const profileData = {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        JobTitle: "",
        City: "",
        State: "",
        Country: "",
        Zip: "",
        PhoneNumberVerified: "",
        EmailVerified: "",
        CompanyName: "",
        PhoneCode: "",
        CountryCode: "",
        TimezoneId: ""
    }

    const passwordData = {
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: ""
    }

    const initImg = {
        img: userImg
    }


    const [mins, setMinutes] = useState("15")
    const [over, setOver] = React.useState(false);
    const [formData, setData] = useState(profileData);
    const [formPassData, setPassData] = useState(passwordData);
    const [passErrors, setPassErrors] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [countryList, getCountryList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(initImg);
    const [verifyPhone, setVerifyPhone] = useState(false)
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [verifyPhoneicon, setPhoneIconCheck] = useState();
    const [verifyEmailIcon, setEmailIconCheck] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setCode("") }
    const [showEmail, setShowEmail] = useState(false);
    const handleCloseEmail = () => { setShowEmail(false); setCodeEmail("") }
    const [mobileNumberVerfiy, setMobileNumberVerify] = useState();
    const [emailVerfiy, setEmailVerify] = useState();
    const [timeZoneList, gettimeZoneList] = useState([]);
    const [phoneNumberVerifiedform, setPhoneNumberVerified] = useState(false);
    const [emailVerifiedform, setEmailverified] = useState(false)
    const [mobValue, setMobValue] = useState()
    const [PhoneNumber, setPhoneNumber] = useState();
    const [PhoneNumberOnly, setPhoneNumberOnly] = useState();
    const [countryCodeNew, setCountryCodeNew] = useState()
    const ref = React.useRef();
    useEffect(() => {
        getCountry();
        getMyProfile();
    }, []);

    const getCountry = async () => {
        var res = await postRequest('/api/Master/GetCounty');
        getCountryList(JSON.parse(res?.data?.Data));

    }
    const [alert, setAlert] = useState(true);
    const closeAlert = () => {
        setAlert(false);
    }

    const getMyProfile = async () => {
        var res = await postRequest('/api/Verification/GetMyProfile');
        let result = JSON.parse(res?.data?.Data);
        gettimeZoneList(JSON.parse(result?.TimeZoneList));
        setMobileNumberVerify(result.PhoneNumberVerified)
        setEmailVerify(result.EmailVerified)
        setPhoneIconCheck(result.PhoneNumber)
        setEmailIconCheck(result.Email)
        setData({
            CompanyName: result.CompanyName,
            FirstName: result.FirstName,
            LastName: result.LastName,
            PhoneNumber: result.PhoneNumber,
            Email: result.Email,
            JobTitle: result.JobTitle,
            City: result.City,
            State: result.State,
            Country: result?.Country,
            Zip: result.PostalCode,
            EmailVerified: result.EmailVerified,
            PhoneNumberVerified: result.PhoneNumberVerified,
            PhoneCode: result.PhoneCode,
            CountryCode: result.CountryCode,
            TimezoneId: result?.TimezoneId
        })
        setPhoneNumberOnly(result.PhoneNumber)
        setDialCode(result.PhoneCode)
        setCountryCodeNew(result.CountryCode)
        setMobValue(`${result.PhoneCode}${result.PhoneNumber}`)

        if (result !== undefined) {
            // var preview = document.getElementById('imagePreview');
            let preview = 'data:image/jpeg;base64,'
            setSelectedFile({
                ...selectedFile,
                img: preview + result.ProfileImage
            })


            // preview.src = 'data:image/jpeg;base64,' + result.ProfileImage;
        }
    }
    useEffect(() => {
        if (mobileNumberVerfiy === false) {
            setVerifyPhone(true)

        }
        else if (mobileNumberVerfiy === true) {
            setVerifyPhone(false)
        }
    }, [mobileNumberVerfiy])



    useEffect(() => {
        if (emailVerfiy === false) {
            setVerifyEmail(true)

        }
        else if (emailVerfiy === true) {
            setVerifyEmail(false)
        }
    }, [emailVerfiy])

    const handleChange = (event, values) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });

    }
    const [dialCode, setDialCode] = useState()
    const [countryCodePhone, setCountryCodePhone] = useState();
    let [errorMob, setErrorMob] = React.useState("");
    const handleChangeMob = (mobValue, data, event) => {
        setMobValue(mobValue);
        setDialCode(data.dialCode)
        setCountryCodePhone(data.countryCode)
        let Phone = mobValue.slice(data.dialCode.length)
        setPhoneNumber(Phone)
        if (verifyPhoneicon == Phone && mobileNumberVerfiy === true ) {
            setData(prevForm => {
                return {
                    ...prevForm,
                    PhoneNumberVerified: true
                }
            });
            setVerifyPhone(false)
            setMobValue(mobValue)
        }
        else {
            setData(prevForm => {
                return {
                    ...prevForm,
                    PhoneNumberVerified: false
                }
            });
            setMobValue(mobValue)
            setVerifyPhone(true)
        }
        setMobShowDisable(false)
        // let concat = mobValue?.phoneNumber
        // let remove = concat?.toString()
        // let Phone = remove?.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/ ]/gi, '')
        // setPhoneNumber(Phone)
        // setPhoneCode(mobValue?.countryCode);
    }
    const errorMessageMob = (message) => {
        setErrorMob(message);
        // setTimeout(() => setError(""), 2000);
    }
    const handleChangeCountry = (event) => {
        const { name, value } = event.target
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const handleChangeMobileNumberVerify = (event, values) => {
        setShowDisable(false);
        const { name, value } = event.target
        if (verifyPhoneicon == value && mobileNumberVerfiy === true) {
            setData(prevForm => {
                return {
                    ...prevForm,
                    [name]: value,
                    PhoneNumberVerified: true
                }
            });
            setVerifyPhone(false)
        }
        else {
            setData(prevForm => {
                return {
                    ...prevForm,
                    [name]: value,
                    PhoneNumberVerified: false
                }
            });
            setVerifyPhone(true)
        }



    }
    const handleChangeEmailVerify = (event) => {
        const { name, value } = event.target
        setShowDisable(false)
        if (verifyEmailIcon == value && emailVerfiy === true) {
            setData(prevForm => {
                return {
                    ...prevForm,
                    [name]: value,
                    EmailVerified: true
                }
            });
            setVerifyEmail(false)
        }
        else {
            setData(prevForm => {
                return {
                    ...prevForm,
                    [name]: value,
                    EmailVerified: false
                }
            });
            setVerifyEmail(true)
        }
    }

    const handlePasswordChange = (event, values) => {
        const { name, value } = event.target
        setPassData(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(formData)
        // if (PhoneNumber == null) {
        //     errorMessageMob("Mobile number is required");
        //     return;
        // }
        // if (PhoneNumber.length > 9) {
        //     errorMessageMob("Invalid Mobile number");
        //     return;
        // }

        setFormErrors(validate(formData));
        if (mobValue.length == 0) {
            errorMessageMob("Mobile number is required");
            return;
        }

        // if (PhoneNumber.length < 8) {
        //     errorMessageMob("Invalid mobile number");
        //     return;
        // }
        if (formData.PhoneNumberVerified === false) {
            toast.error("Verify mobile number")
            errorMessageMob(" ");
        }
        else if (formData.EmailVerified === false) {
            toast.error("Verify email")
        }
        else {
            setLoading(true);
            if (Object.keys(validate(formData)).length === 0) {

                const data = new FormData();
                data.append('ImageDocument', selectedFile.imgFile)
                data.append('CompanyName', formData.CompanyName)
                data.append('FirstName', formData.FirstName)
                data.append('LastName', formData.LastName)
                data.append('Email', formData.Email)
                data.append('PhoneNumber', PhoneNumber != undefined ? PhoneNumber : verifyPhoneicon)
                data.append('JobTitle', formData.JobTitle)
                data.append('City', formData.City)
                data.append('State', formData.State)
                data.append('Country', formData.Country)
                data.append('Zip', formData.Zip)
                data.append('PhoneNumberVerified', formData.PhoneNumberVerified)
                data.append('EmailVerified', formData.EmailVerified)
                data.append('PhoneCode', dialCode)
                data.append('CountryCode', countryCodePhone == undefined ? countryCodeNew : countryCodePhone)
                data.append('TimezoneId', formData.TimezoneId == null ? "" : formData.TimezoneId)

                postFormData('api/Verification/UpdateProfile', data).then((res) => {
                    debugger;
                    setLoading(false);
                    if (!res?.data?.Status) {
                        if (res?.data?.ErrorMessage === 'Already Exist') {
                            toast.error('The email or mobile number already exists');
                        }
                        else {
                            toast.error(res?.data?.ErrorMessage);
                        }
                    }
                    else {
                        toast.success('Profile updated successfully');
                        getMyProfile();
                    }
                });

            }
            else {
                setLoading(false);

            }
        }


    }
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        if (!values.FirstName) {
            errors.FirstName = "First name is required";
        }
        // if (!values.LastName) {
        //     errors.LastName = "Last Name is required";
        // }
        if (!values.Email) {
            errors.Email = "Email is required";
        } else if (!regex.test(values.Email)) {
            errors.Email = "Invalid email";
        }

        // if (!values.PhoneNumber) {
        //     errors.PhoneNumber = "Mobile Number is required";
        // }
        // else if (!phone.test(values.PhoneNumber)) {
        //     errors.PhoneNumber = "Enter a valid Mobile Number";
        // }

        return errors;
    };

    const handleKeyPressalpha = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }
    const handleKeyPressalphaLastName = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z ]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }

    const handleKeyPressnumber = (e) => {
        var key = e.key;
        var regex = /^[0-9]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }
    const handleKeyPressnumberZip = (e) => {
        var key = e.key;
        var regex = /^[a-zA-Z0-9]*$/;
        if (!regex.test(key)) {
            e.preventDefault();
        }

    }

    const handlePasswordSubmit = (event) => {

        event.preventDefault();
        setPassErrors(validatePassword(formPassData));

        if (Object.keys(validatePassword(formPassData)).length === 0) {
            let data = {
                OldPassword: formPassData.OldPassword,
                NewPassword: formPassData.NewPassword
            }
            setLoadingPassword(true);
            postRequest('api/Verification/UpdatePassword', data).then((res) => {
                if (!res?.data?.Status) {
                    toast.error(res?.data?.ErrorMessage);
                }
                else {
                    setLoadingPassword(false);
                    toast.success('Reset password updated successfully');
                    setPassData({
                        OldPassword: "",
                        NewPassword: "",
                        ConfirmPassword: ""
                    });
                    setIconChange(false);
                    setPasswordShown(false);
                    setIconChange1(false);
                    setPasswordShown1(false);
                    setIconChange2(false);
                    setPasswordShown2(false);
                }
            });
        }

    }

    const validatePassword = (values) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        const errors = {};
        if (!values.OldPassword) {
            errors.OldPassword = "Old password is required";
        }
        if (!values.NewPassword) {
            errors.NewPassword = "New password is required";
        }
        if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password is required";
        }
        else if (!regex.test(values.NewPassword)) {
            errors.NewPassword = "Please strengthen the password";
        }
        else if (values.NewPassword == values.OldPassword) {
            errors.NewPassword = "Old password and new password cannot be same";
        }

        if (values.NewPassword != values.ConfirmPassword) {
            errors.ConfirmPassword = "Confirm password do not match";
        }
        return errors;
    };

    const handleInputFile = (event) => {

        if (event.target.files && event.target.files[0]) {
            const imgFile = event.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setSelectedFile({
                    ...selectedFile,
                    imgFile,
                    img: x.target.result
                })
            }
            reader.readAsDataURL(imgFile);
        }

        // var preview = document.getElementById('imagePreview');
        // const reader = new FileReader();
        // const file = event.target.files[0];

        // setSelectedFile(file);
        // reader.onloadend = () => {
        //     preview.src = reader.result;
        // }
        // reader.readAsDataURL(file);
    }

    // mobile number verify with otp
    const [code, setCode] = useState("");
    const [codeEmail, setCodeEmail] = useState("");
    const handleChangeEmailOTP = (codeEmail) => setCodeEmail(codeEmail);
    const handleChangeMobOTP = (code) => setCode(code);
    let [error, setError] = React.useState("");

    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState()
    const [mobOTPdisplay, setMobOTPdisplay] = useState({})
    const [EmailDisplay, setEmailDisplay] = useState()
    const [emailOTPdisplay, setEmailOTPdisplay] = useState({})
    const [verifiedMobileNumber, setVerifiedMobileNumber] = useState(false)

    const [verifiedEmail, setVerifiedEmail] = useState(false)
    const [mpbShowDisable, setMobShowDisable] = useState(false)
    const errorMessage = (message) => {
        setError(message);
    }

    const verifyPhoneNumberOTP = (phoneNumber) => {
        errorMessage("")
        if (mobValue == undefined || mobValue.length == 0) {
            errorMessageMob("Mobile number is required");
            return;
        }

        if (PhoneNumber.length < 8) {
            errorMessageMob("Invalid mobile number");
            return;
        }
        setPhoneNumberDisplay(phoneNumber)

        postVerify(`api/Verification/PhoneNumberExists?phoneNumber=${phoneNumber}`, phoneNumber).then((existsMob) => {
            if (existsMob.data.Status) {
                setShow(true);
                const value = {
                    VerificationValue: phoneNumber,
                    VerificationType: "m"
                }
                postVerify('/api/Verification/SendVerification', value).then((res) => {
                    if (res) {
                        let result = JSON.parse(res?.data?.Data)
                        errorMessageMob("")
                        setMobOTPdisplay(result)
                        toast.success("Mobile verification SMS sent successfully")
                    }

                });
            }
            else if (existsMob.data.ErrorMessage == "Already Exists") {
                setShow(false);
                toast.error("Mobile number already exists")
            }
            else {
                setShow(false);
                toast.error("Mobile number already exists")
            }
        });
    }
    const OTPverifyMob = (e) => {
        e.preventDefault();
        if (code.length == " ") {
            errorMessage("Enter Code");
            return;
        }
        else if (code.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }
        if (code === mobOTPdisplay.VerificationCode) {
            toast.success("Mobile number verified successfully")
            errorMessage(" ");
            setShow(false);
            setVerifiedEmail(true)
            setMobShowDisable(true)
            setVerifyPhone(false)
            setCode("")
            errorMessageMob("")
            setData(prevForm => {
                return {
                    ...prevForm,
                    PhoneNumberVerified: true
                }
            });
            setPhoneNumberVerified(true)
        }
        else {
            errorMessage("Enter valid code");
        }
    }
    const [resetOTP, setResetOTP] = useState(false)
    useEffect(() => {
        resendMobOTP()
    }, [])
    const resendMobOTP = async (phoneNumber) => {
        setOver(false)
        setCode("")
        errorMessage("")
        setResetOTP(!resetOTP)
        const PhoneInput = {
            VerificationValue: phoneNumber,
            VerificationType: "m"
        }
        await postVerify('/api/Verification/SendVerification', PhoneInput).then((res) => {
            if (res) {
                let result = JSON.parse(res?.data?.Data)
                toast.success("Mobile verification SMS resent successfully")
                setMobOTPdisplay(result);
            }

        });
    }


    // email verification for my profile page

    const verifyEmailOTP = (Email) => {
        errorMessage("")
        setEmailDisplay(Email)
        postVerify(`api/Verification/EmailExists?email=${Email}`, Email).then((existsEmail) => {
            if (existsEmail.data.Status) {
                setShowEmail(true);
                const value = {
                    VerificationValue: Email,
                    VerificationType: "e"
                }
                postVerify('/api/Verification/SendVerification', value).then((res) => {
                    if (res) {
                        let result = JSON.parse(res?.data?.Data)
                        toast.success("Email verification sent successfully")
                        setEmailOTPdisplay(result)
                    }

                });
            }
            else if (existsEmail.data.ErrorMessage == "Already exists" && emailVerfiy === true) {
                setShowEmail(false);
                toast.error("Email already exists")
            }
        });
    };
    const [ishowDisable, setShowDisable] = useState(false)
    const resendEmailOTP = async (Email) => {
        setOver(false)
        setCodeEmail("")
        errorMessage("")
        setResetOTP(!resetOTP)
        const EmailInput = {
            VerificationValue: Email,
            VerificationType: "e"
        }
        await postVerify('/api/Verification/SendVerification', EmailInput).then((res) => {
            if (res) {
                let result = JSON.parse(res?.data?.Data)
                toast.success("Email verification resent successfully")
                setEmailOTPdisplay(result);
            }

        });
    }

    const OTPverifyEmail = (e) => {
        e.preventDefault();
        if (codeEmail.length == " ") {
            errorMessage("Enter code");
            return;
        }
        else if (codeEmail.length !== 6) {
            errorMessage("Enter valid code");
            return;
        }
        if (codeEmail === emailOTPdisplay.VerificationCode) {
            toast.success("Email Verified Successfully")
            errorMessage(" ");
            setShowEmail(false);
            setShowDisable(true);
            setVerifiedMobileNumber(true)
            setVerifyEmail(false)
            setCodeEmail("")
            setData(prevForm => {
                return {
                    ...prevForm,
                    EmailVerified: "true"
                }
            });
            setEmailverified(true)
        }
        else {
            errorMessage("Enter valid code");
        }
    }


    return (
        <SideNavbar>
            <div>
                <Container fluid className='p-0 g-0' >
                    <Row className='g-0'>
                        <Col lg={6} xs={12} sm={12} className="mob-none">
                            <div className='breadcrumps-box'>
                                <div className='breadcrump-heading'>
                                    <p>My Profile</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                            <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                <Typography className='text-white-breadcrums cus-mr-5' >My Profile</Typography>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Container fluid className='p-4'>
                        <Row>
                            <Col lg={6}>
                                {alert && <Alert className='alert-points-box  m-0 my-2' variant="success">
                                    <span style={{ fontSize: "14px" }}>Update the profile for better user experience</span>
                                    <FaTimes className='close-alert-notification' onClick={closeAlert} />
                                </Alert>}
                                <div className='bg-white px-4 py-2' style={{ height: "590px !importent;" }}>
                                    <div className='searched-table-heading'>
                                        <h3>My Profile Details</h3>
                                    </div>
                                    <form autocomplete="off" >
                                        <div className='search-profile-form-myprofile'>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressalpha(e)} value={formData.CompanyName} name="CompanyName" maxLength="25" type="text" placeholder=" " readOnly="true" />
                                                <div className='icon-background' > <FaUsers className='user-input-icon styel-organization-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label"> Organization Name *</label>
                                                <p className="mb-1 form-error form-error">{formErrors.CompanyName}</p>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressalpha(e)} value={formData.FirstName} name="FirstName" maxLength="25" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">First Name *</label>
                                                <p className="mb-1 form-error form-error">{formErrors.FirstName}</p>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.LastName} onKeyPress={(e) => handleKeyPressalphaLastName(e)} maxLength="25" name="LastName" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Last Name </label>
                                                <p className="mb-1 form-error">{formErrors.LastName}</p>
                                            </div>

                                            <div className="align-myprofile-form-phone did-floating-label-content mb col-lg-9 col-sm-12 mb-sm-0-cus" >
                                                <PhoneInput
                                                    label="MobileNumber"
                                                    onChange={handleChangeMob}
                                                    placeholder="Mobile Number *"
                                                    value={mobValue}
                                                    defaultCountry={'us'}
                                                    autoCorrect="off"
                                                    country={'us'}

                                                />
                                                <p className="mb-lg-1 form-error">{errorMob}</p>
                                                {/* <input className="did-floating-input" autocomplete="new-password" onChange={handleChangeMobileNumberVerify} value={formData.PhoneNumber} maxLength="12" onKeyPress={(e) => handleKeyPressnumber(e)} name="PhoneNumber" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaPhoneAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Mobile Number *</label>
                                                <p className="mb-1 form-error">{formErrors.PhoneNumber}</p> */}
                                                {verifyPhone ? <div className='position-mob-verify-icon'>  <FaTimesCircle className='veryfied-icon-false' /> </div> : <div className='position-mob-verify-icon'>  <FaRegCheckCircle className='veryfied-icon' /></div>}
                                            </div>
                                            <div className='col-lg-3 col-sm-12 mob-width-timezone'>
                                                <Link to="/myprofile">   <button className={verifyPhone ? 'btn-verify-myprofile' : "btn-verified-myprofile-mobile"} disabled={mpbShowDisable} onClick={() => verifyPhoneNumberOTP(PhoneNumber == "" || PhoneNumber == undefined ? PhoneNumberOnly : PhoneNumber)}>{verifyPhone ? "Send verification SMS" : "Verified"}  </button></Link>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-9 col-sm-12 mb-sm-0-cus" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChangeEmailVerify} value={formData.Email} name="Email" maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Email *</label>
                                                <p className="mb-1 form-error">{formErrors.Email}</p>
                                                {verifyEmail ? <div className='position-mob-verify-icon'> <FaTimesCircle className='veryfied-icon-false' /> </div> : <div className='position-mob-verify-icon'><FaRegCheckCircle className='veryfied-icon' /></div>}
                                            </div>
                                            <div className='col-lg-3 col-sm-12 mob-width-timezone' >
                                                <Link to="/myprofile">   <button className={verifyEmail ? 'btn-verify-myprofile' : "btn-verified-myprofile-email"} disabled={ishowDisable} onClick={() => verifyEmailOTP(formData.Email)}>{verifyEmail ? "Send verification email" : "Verified"}</button></Link>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} value={formData.JobTitle} name="JobTitle" maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Job Title</label>
                                                <p className="mb-1 form-error form-error">{formErrors.JobTitle}</p>
                                            </div>


                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressalphaLastName(e)} value={formData.City} name="City" maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaCity className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">City</label>
                                                <p className="mb-1 form-error form-error">{formErrors.City}</p>
                                            </div>

                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressalphaLastName(e)} value={formData.State} name="State" maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaBuilding className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">State</label>
                                                <p className="mb-1 form-error form-error">{formErrors.State}</p>
                                            </div>

                                            <div class="col-lg-12 col-sm-12 mb-4 mui-overide mui-select-override mob-width-timezone">
                                                <FormControl fullWidth className='b-0 '>
                                                    <InputLabel id="demo-simple-select-label1" className='material-ui-select '>Country</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label1"
                                                        id="demo-simple-select1"
                                                        label="Country"
                                                        className=''
                                                        variant="outlined"
                                                        disabled={true}
                                                        name='Country'
                                                        value={formData.Country}
                                                        onChange={handleChange}>
                                                        {
                                                            countryList.map(job => (<MenuItem key={job.CountryId} value={job.CountryId}>{job.CountryName}</MenuItem>))
                                                        }
                                                    </Select>
                                                    <div className='icon-background' > <FontAwesomeIcon icon={faSchoolFlag} className='user-input-icon' /></div>
                                                </FormControl>
                                                <p className="mb-1 form-error">{formErrors.Country}</p>
                                            </div>

                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handleChange} onKeyPress={(e) => handleKeyPressnumberZip(e)} value={formData.Zip} name="Zip" maxLength="10" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaAddressCard className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Zip Code</label>
                                                <p className="mb-1 form-error form-error">{formErrors.Zip}</p>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 mb-4 mui-overide mui-select-override mob-width-timezone">
                                                <FormControl fullWidth className='b-0 '>
                                                    <InputLabel id="demo-simple-select-label12" className='material-ui-select '>Time Zone</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label12"
                                                        id="demo-simple-select12"
                                                        label="Time Zone"
                                                        className=''

                                                        variant="outlined"
                                                        name='TimezoneId'
                                                        value={formData.TimezoneId}
                                                        onChange={handleChangeCountry}>
                                                        {
                                                            timeZoneList.map((job) => (<MenuItem key={job.TimezoneId} value={job.TimezoneId}>{job.TimeZoneValue}</MenuItem>))
                                                        }
                                                    </Select>
                                                    <div className='icon-background' > <FontAwesomeIcon icon={faSchoolFlag} className='user-input-icon' /></div>
                                                </FormControl>
                                                <p className="mb-1 form-error">{formErrors.TimezoneId}</p>
                                            </div>
                                        </div>
                                        <div className="search-reset-btn mt-1 mb-2">
                                            <button className='search-btn reset' onClick={handleSubmit} disabled={loading}> {loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null
                                            } Update</button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='bg-white px-4 py-2'>
                                    <div className='searched-table-heading'>
                                        <h3>My Profile Picture</h3>
                                    </div>

                                    <div class="avatar-upload">
                                        <div class="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleInputFile} ref={ref} />
                                            <label for="imageUpload" className='img-profile-edit-mob'><FaPen className='user-input-icon-myprofile' /></label>
                                        </div>
                                        <div class="avatar-preview">
                                            <img id="imagePreview" src={selectedFile.img == "data:image/jpeg;base64,undefined" ? userImg : selectedFile.img}></img>

                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white px-4 mt-3 py-2'>
                                    <div className='searched-table-heading'>
                                        <h3>Change Password</h3>
                                    </div>

                                    <form autocomplete="off" >
                                        <div className='search-profile-form'>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handlePasswordChange} value={formPassData.OldPassword} name="OldPassword" maxLength="20" type={passwordShown ? "text" : "password"} placeholder=" " />
                                                <div className='icon-background' > <FaKey className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Old Password *</label>
                                                <p className="mb-1 form-error form-error">{passErrors.OldPassword}</p>
                                                {iconChange ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle} /></div>}
                                            </div>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handlePasswordChange} value={formPassData.NewPassword} name="NewPassword" maxLength="20" type={passwordShown1 ? "text" : "password"} placeholder=" " />
                                                <div className='icon-background' > <FaKey className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">New Password *</label>
                                                <p className="mb-1 form-error form-error">{passErrors.NewPassword}</p>
                                                {iconChange1 ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle1} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle1} /></div>}
                                                <OverlayTrigger
                                                    placement="top"

                                                    overlay={<Tooltip > Password must contain minimum 8-20 characters,<br /> at least one lowercase,<br /> at least one uppercase, <br />at least one number and<br /> at least one special character</Tooltip>}>

                                                    <span className="tooltip-alignpassword">
                                                        {<FaInfoCircle />}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="did-floating-label-content  col-lg-12 col-sm-12" >
                                                <input className="did-floating-input" autocomplete="new-password" onChange={handlePasswordChange} value={formPassData.ConfirmPassword} name="ConfirmPassword" maxLength="20" type={passwordShown2 ? "text" : "password"} placeholder=" " />
                                                <div className='icon-background' > <FaKey className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Confirm Password *</label>
                                                <p className="mb-1 form-error form-error">{passErrors.ConfirmPassword}</p>
                                                {iconChange2 ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye-subuser' onClick={iconToggle2} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye-subuser' onClick={iconToggle2} /></div>}
                                            </div>
                                        </div>
                                        <div className="search-reset-btn mt-1 mb-2">
                                            <button className='search-btn reset' onClick={handlePasswordSubmit} disabled={loadingPassword}> {loadingPassword ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null
                                            } Reset</button>
                                            <input type="reset" className='reset-btn reset' id="reset" value="Clear" onClick={() => {
                                                setPassData({
                                                    OldPassword: "",
                                                    NewPassword: "",
                                                    ConfirmPassword: ""
                                                }); setPassErrors({}); setIconChange(false);
                                                setPasswordShown(false);
                                                setIconChange1(false);
                                                setPasswordShown1(false);
                                                setIconChange2(false);
                                                setPasswordShown2(false);
                                            }} />
                                        </div>
                                    </form>
                                </div>

                            </Col>

                        </Row>
                    </Container>
                </div >
                <Modal show={show} onHide={handleClose}

                    size="xl"
                    dialogClassName="modal-100w"
                    centered
                    aria-labelledby="example-custom-modal-styling-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                            Verify Mobile Number {mobOTPdisplay.VerificationCode}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={OTPverifyMob}>
                            <div className='verification-layout-css align-verify'>
                                <div className='mobile-verify-content'>
                                    <h1>Mobile Number Verification</h1>
                                </div>

                                <h4 className='mt-1  px-5'>Verification code has been sent to your {phoneNumberDisplay} mobile number</h4>

                                <div className='otp-align mt-1'>
                                    {over ? "" : <OtpInput
                                        value={code}
                                        onChange={handleChangeMobOTP}
                                        numInputs={6}
                                        separator={<span style={{ width: "8px" }}></span>}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: "1px solid #CFD3DB",
                                            borderRadius: "8px",
                                            width: "54px",
                                            height: "54px",
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "blue"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none",
                                        }}
                                        className="otp-input"
                                    />}

                                </div>
                                {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                <div className='mt-1  verify-timing '><div> {over ? "" : <div> Code expires in</div>}</div> <div className='counter'>
                                    {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                </div> </div>
                                <div className='mt-1 '>
                                    {over ? "" : <button className='btn-verify' >Submit</button>}
                                </div>
                                <div className=' resend-otp'> {over ? <p><span onClick={() => resendMobOTP(phoneNumberDisplay)} className=' resend-otp'>Resend Code </span> </p> : <p> Didn’t receive the code? <span onClick={() => resendMobOTP(phoneNumberDisplay)} className=' resend-otp'>Resend Code </span> </p>}
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal show={showEmail} onHide={handleCloseEmail}

                    size="xl"
                    dialogClassName="modal-100w"
                    centered
                    aria-labelledby="example-custom-modal-styling-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                            Verify Email {emailOTPdisplay.VerificationCode}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={OTPverifyEmail}>
                            <div className='verification-layout-css align-verify'>
                                <div className='mobile-verify-content'>
                                    <h1>Email Verification</h1>
                                </div>

                                <h4 className='mt-1  px-5'>Verification code has been sent to your {EmailDisplay} email</h4>

                                <div className='otp-align mt-1'>
                                    {over ? "" : <OtpInput
                                        value={codeEmail}
                                        onChange={handleChangeEmailOTP}
                                        numInputs={6}
                                        separator={<span style={{ width: "8px" }}></span>}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: "1px solid #CFD3DB",
                                            borderRadius: "8px",
                                            width: "54px",
                                            height: "54px",
                                            fontSize: "17px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "blue"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none"
                                        }}
                                        className="otp-input"
                                        disabledStyle={"disabled"}
                                    />}

                                </div>
                                {over ? "" : <p className="mb-1 form-error">{error}</p>}
                                <div className='mt-1  verify-timing '><div>{over ? "" : <div> Code expires in</div>}</div> <div className='counter'>
                                    {resetOTP ? <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={false} /> : <CountDown minutes={mins} setOver={setOver} over={over} resetOTP={true} />}
                                </div> </div>
                                <div className='mt-1 '>
                                    {over ? "" : <button className='btn-verify' >Submit</button>}
                                </div>
                                <div className=' resend-otp'> {over ? <p><span onClick={() => resendEmailOTP(EmailDisplay)} className=' resend-otp'>Resend Code </span> </p> : <p> Didn’t receive the code? <span onClick={() => resendEmailOTP(EmailDisplay)} className=' resend-otp'>Resend Code </span> </p>}</div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <Footer />
            </div >
        </SideNavbar>

    )
}
export default MyProfile;