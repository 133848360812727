import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Moment from 'moment';
import { postRequest } from '../Services/api';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
const Feedback = (props) => {
    let isLoggedIn = JSON.parse(localStorage.getItem('token'))
    const { show, onClose } = props;
    const [initialEditorState, setInitialEditorState] = useState(
        () => EditorState.createEmpty()
    );
    const [editorState, setEditorState] = useState(initialEditorState);
    const html = convertToHTML(editorState.getCurrentContent());

    const feedbackHandle = async () => {
        if (html != "<p></p>") {
            const data = new FormData()
            data.append('UserId', isLoggedIn?.userId)
            data.append('CompanyId', isLoggedIn?.CompanyId)
            data.append('FeedBack', html)
            await postRequest(`api/ScheduleInterview/FeedBack`, data).then((res) => {
                if (res?.data?.Status) {
                    toast.success("Thanks for your feed back");
                    setEditorState(initialEditorState);
                }
            })
        }
    }
    return (
        <div>

            <Modal
                show={show} onHide={onClose}
                size="lg"
                dialogClassName="modal-100w"
                centered
                aria-labelledby="example-custom-modal-styling-title-lg"
            >
                <Modal.Header closeButton  >
                    <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='title-interview-docs'>
                        <h4>Feed Back</h4>
                    </div>
                    <Editor
                        placeholder="Add Notes"
                        defaultEditorState={editorState}
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'textAlign', 'history'],
                            inline: { inDropdown: false },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                        initialEditorState={initialEditorState}
                    />
                    <div className='add-editor-cancel-align mt-3'>
                        <button className='search-btn reset' onClick={feedbackHandle}>Send</button>

                        <button className='reset-btn reset' onClick={() => { setEditorState(initialEditorState); }}>Cancel</button>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Feedback