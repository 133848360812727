import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const Footer = () => {
  return (
    <div>
        <Container fluid className='px-4 mt-2 py-4'>
            <Row>
                <Col className='footer-link'>
                    <p>Copyright © 2022 <span>projectR</span>. All Rights Reserved.</p>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Footer