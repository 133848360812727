import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Logo from '../../assets/img/logo.png'
import { useLocation } from 'react-router-dom';
import { postRequest,postRequestOffer } from '../Services/api';
import Spinner from 'react-bootstrap/Spinner';

const OfferStatus = () => {
    const location = useLocation()
    const path = location.pathname.split('/id=');
    const [isAlreadyExists, setisAlreadyExists] = useState(false);
    let id = parseInt(path[1]);
    const values = location.pathname.split('&value=');
    const [loading, setLoading] = useState(true)
    let value = values[1];

    useEffect(() => {
        offerStatusChange();
        notificationStatus();
    }, [])

    const offerStatusChange = () => {
        postRequestOffer(value === "yes" ? 'api/Profile/AccountEnable?profileId=' + id + '&status=' + 'offered'+'&isextrenal=true' : 'api/Profile/AccountEnable?profileId=' + id + '&status=' + 'declined'+'&isextrenal=true').then((res) => {
            let result = JSON.parse(res?.data?.Data);
            if (result.Status == 'Already exists') {
                setLoading(false)
                setisAlreadyExists(true);
            }
            else {
                setLoading(false)
                setisAlreadyExists(false);
            }
        });
    }
    const notificationStatus = () => {
        let isAccept = true
        let declined = false
        let offerStatus = "Offer status"
        if (value === "yes") {
            postRequest('api/Notification/OfferNotificationInsert?ProfileId=' + id + '&notificationsource=' + offerStatus + '&isAccepted=' + isAccept);
        }
        else {
            postRequest('api/Notification/OfferNotificationInsert?ProfileId=' + id + '&notificationsource=' + offerStatus + '&isAccepted=' + declined);
        }
    }

    return (
        <div>
            <div className='bg-signup'>

                <div className=''>
                    <Container fluid className='p-lg-5'>
                        <Row >
                            <Col lg={3}></Col>
                            <Col lg={6} className='bg-white rounded-box3 p-lg-2 pt-1 pb-1 px-4 py-2'>
                                <div className='d-flex  justify-content-between'>

                                </div>
                                <div className='text-center mt-5 signin-heading'>
                                    <img src={Logo} className='img-fluid' />

                                    
                                    <h4>  

                                    {isAlreadyExists ? 'Thank you for your confirmation' : 'Thank you for your confirmation'}
                                   
                                    </h4>
                                    {/* {loading? <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>:"" } */}
                                   
                                </div>
                            </Col>
                            <Col lg={3}></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default OfferStatus