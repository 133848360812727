import React from 'react'
import FooterInterView from './FooterInterView'
import browser from '../../../assets/img/browser.png'
const BrowserChanged = () => {
    return (
        <div>
            <div className='thankyou-h-100'>
                <div className='browser__page__ui'>
                    <div className='box-of-browserchange'>
                        <img src={browser} className='img-icon-thankyou' />
                        <h1 >Browser changing has been detected </h1>
                        <p >Browser changed while your interview process, please contact your organization</p>
                    </div>

                </div>
            </div>

            <FooterInterView />
        </div>
    )
}

export default BrowserChanged