import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
import { Pagination } from 'react-bootstrap'
import { Modal, Col, Container, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import { FaEye, FaUserAlt, FaEnvelope, FaCalendarAlt, FaPenSquare, FaTrashAlt } from "react-icons/fa";
import { FaChevronRight, FaToggleOff, FaToggleOn } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { postRequest } from '../Services/api';
import Moment from 'moment';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import PaginationComponent from './Pagination';
import Search from './Search';
import _, { truncate } from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const SubuserTable = forwardRef((props, ref) => {
    let { setAllowCreate, onDelete } = props
    let userIdLocal = JSON.parse(localStorage.getItem('token'))
    // console.log(allowCreate)
    let tokens = useSelector((state) => state.userAuth.value);
    useEffect(() => {
        // setAuthToken(tokens)
        tokens = tokens;
    }, [tokens]);
    let UserID = userIdLocal?.userId
    const [loading, setLoading] = useState(true)
    const [subUserLists, setSubUserList] = useState();
    const [subUserList, getSubUserList] = useState([]);
    const [showSubUser, setShowSubUser] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [candidateName, setCandidateName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [searchList, getSearchList] = useState([]);
    const [deleteSubUser, setDeleteSubuser] = useState()
    const [status, setStatus] = useState()
    // const [allowCreate, setAllowCreate] = useState([]);
    // console.log(companyName)
    useEffect(() => {
        getSubUserLists()
        handleDeleteSubuser()
        setDeleteSubuser()
        handleActiveUser()
        setSubUserList()
    }, [status])
    const getSubUserLists = async () => {
        setLoading(truncate);
        var res = await postRequest(`/api/Profile/GetSubUser?UserId=${UserID}`);
        var result = JSON.parse(res?.data?.Data);
        result.map((item) => {
            item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
        });

        setSubUserList(result);
        setLoading(false);
    }
    const handleActiveUser = async (userId, status) => {
        await postRequest('/api/Verification/UserStatusUpdate?userid=' + userId + '&IsActive=' + status).then((res) => {
            var result = JSON.parse(res?.data?.Data);
            setStatus(result)
            if (res?.data.Status) {
                if (!status) {
                    toast.success('User deactivated successfully')
                }
                else {
                    toast.success('User activated successfully')
                }
                getSubUserList();
            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    };
    const handleDeleteSubuser = async (userId, index) => {

        await postRequest(`/api/Verification/DeleteSubUser?subUserId=${userId}`).then((res) => {
            // var result = JSON.parse(res?.data?.Data);
            let deletes = res?.data?.Status
            setDeleteSubuser(deletes)
            if (res?.data.Status) {
                onDelete()
                toast.success('Subuser deleted successfully')

            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    }
    const handleResetPassword = async (userId) => {
        await postRequest('/api/Authentication/AdminPasswordReset?userid=' + userId).then((res) => {
            if (res?.data.Status) {
                toast.success('User password resent mail send successfully');
            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    };
    const handleShowSubUserDetail = async (userId, companyName) => {
        setShowSubUser(false);
        await postRequest('api/Profile/SelectSubUser?UserId=' + userId).then((res) => {
            var result = JSON.parse(res?.data?.Data);
            if (result != null) {
                getSubUserList(result)
                setCompanyName(companyName)
                setShow(true);
                setShowSubUser(true);
            }
            else {
                setShow(false);
                setShowSubUser(false);
                toast.error('There is no users found');
            }


        })
    };
    useEffect(() => {
        getSubUserLists();
        handleDeleteSubuser();
    }, [deleteSubUser])

    useImperativeHandle(ref, () => ({
        getSubUserListChild() {
            getSubUserLists();
            setAllowCreate();
            onDelete()
        },
    }), []);

    //   const getSubUserListsDelete = async () => {
    //     var res = await postRequest(`/api/Profile/GetSubUser?UserId=${UserID}`);
    //     var result = JSON.parse(res?.data?.Data);
    //     setAllowCreate(result)
    // }

    return (
        <div>
            <div >
                <Container fluid >
                    <Row>
                        <Col className='bg-white'>


                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>

                                        <th>Subuser name</th>
                                        <th>Job Title</th>
                                        <th>mobile number</th>
                                        <th>email </th>
                                        <th>Organization Name</th>
                                        <th>Registered On</th>
                                        <th>action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ?
                                        <tr>
                                            <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden text-center">Loading...</span>
                                                </Spinner>
                                            </div></td>
                                        </tr> : subUserLists.length == 0 ?
                                            <tr>
                                                <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                            </tr> :

                                            subUserLists.map((item, index) => (
                                                <tr key={index} >

                                                    <td> {item.Name}</td>
                                                    <td>{item.JobTitle}</td>
                                                    <td>{item.PhoneNumber}</td>
                                                    <td>{item.Email}</td>
                                                    <td>{item.CompanyName}</td>
                                                    <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.Zonecode}</td>
                                                    <td >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={item.Status === true ? <Tooltip id="tooltip1">Active</Tooltip> : <Tooltip id="tooltip2">Deactivate</Tooltip>}
                                                        >
                                                            <span> {
                                                                item.Status === true ?
                                                                    <FaToggleOn onClick={() => handleActiveUser(item.UserId, false)} className='mx-2' style={{ cursor: "pointer", fontSize: "large", color: "#e0701a" }} ></FaToggleOn>
                                                                    : <FaToggleOff onClick={() => handleActiveUser(item.UserId, true)} className='mx-2' style={{ cursor: "pointer", fontSize: "large", color: "gray" }} />
                                                            }</span>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip4">Resend Password</Tooltip>}
                                                        ><span> {<FaEnvelope onClick={() => handleResetPassword(item.UserId)} className='mx-2' style={{ cursor: "pointer" }} />}</span>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip3">View</Tooltip>}
                                                        ><span>
                                                                {<FaEye onClick={() => handleShowSubUserDetail(item.UserId, item.CompanyName)} className='mx-2' style={{ cursor: "pointer" }} />}</span>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip3">Delete</Tooltip>}
                                                        ><span> {
                                                            <FaTrashAlt onClick={() => handleDeleteSubuser(item.UserId, index)} className='mx-2' style={{ cursor: "pointer" }} />
                                                        }</span>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </Table>


                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal style={{ zIndex: "999999" }} show={show} onHide={handleClose}
                //   onHide={onClose} 
                size="lg"
                dialogClassName="modal-100w"
                centered
                aria-labelledby="example-custom-modal-styling-title-lg">
                <Modal.Header closeButton className="bg-theme">
                    <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                        {companyName} information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{
                        showSubUser == true ?
                            <Row>
                                {
                                    subUserList?.map((item, index) => (
                                        <Col className="col-md-12 col-lg-12 col-sm-12">
                                            {
                                                index == 0 ? (<div><span style={{ "fontWeight": "700" }}>Registered Subuser</span></div>) : (<div> <span style={{ "fontWeight": "700" }}>Sub User - {index}</span></div>)
                                            }
                                            <Table responsive borderless className='searched-table' style={{ "border": "1px solid black" }}>
                                                <tbody>
                                                    <tr key={index}>
                                                        <td style={{ "fontWeight": "500" }}>Contact Name</td>
                                                        <td>:</td>
                                                        <td>{item.Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Mobile Number</td>
                                                        <td>:</td>
                                                        <td>{item.PhoneNumber == null ? "" : item.PhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Email</td>
                                                        <td>:</td>
                                                        <td>{item.Email == null ? "" : item.Email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Job Title</td>
                                                        <td>:</td>
                                                        <td>{item.JobTitle == null ? "" : item.JobTitle}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>City</td>
                                                        <td>:</td>
                                                        <td>{item.City == null ? "" : item.City}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>State</td>
                                                        <td>:</td>
                                                        <td>{item.State == null ? "" : item.State}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Country</td>
                                                        <td>:</td>
                                                        <td>{item.CountryName == null ? "" : item.CountryName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Zip Code</td>
                                                        <td>:</td>
                                                        <td>{item.PostalCode == null ? "" : item.PostalCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Time Zone</td>
                                                        <td>:</td>

                                                        <td>{item.TimezoneValue == null ? "" : item.TimezoneValue}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Status</td>
                                                        <td>:</td>
                                                        <td>{item.Status == true ? "Active" : "Deactive"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    ))
                                }
                            </Row>
                            :
                            ""
                    }

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
});

export default SubuserTable