import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { postRequest } from '../../Services/api';
const InterviewNotes = ({ profileId, editorState, setEditorState, AddNotes, initialEditorState, NotesupdateButton, UpdateNotes, replyStatus, ReplyNotesAdd, setReplyStatus, setNotesUpdateButton }) => {
    // const [initialEditorState, setInitialEditorState] = useState(
    //     () => EditorState.createEmpty()
    // );
    // const [editorState, setEditorState] = useState(initialEditorState);
    // const [loading, setLoading] = useState(false);
    // const html = convertToHTML(editorState.getCurrentContent());
    // const onEditorStateChange = (editorState) => {
    //     setEditorState(editorState)
    // }
    // const AddNotes = () => {
    //     if (html.length > 0) {
    //         const data = {
    //             "ProfileId": profileId,
    //             "Notes": html,
    //         }

    //         postRequest('api/ScheduleInterview/InsertNotes', data).then((res) => {
    //             console.log(res)
    //         })

    //     }
    // }
    return (
        <div>
            <div className='title-interview-docs'>
                <h4>Interview Notes</h4>
            </div>

            <Editor
                placeholder="Add Notes"
                defaultEditorState={editorState}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'textAlign', 'history'],
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
                initialEditorState={initialEditorState}
            />

            <div className='add-editor-cancel-align mt-3'>
                {NotesupdateButton ? <button className='search-btn reset' onClick={UpdateNotes}>Update</button> : replyStatus ? <button className='search-btn reset' onClick={ReplyNotesAdd}>Reply</button>: <button className='search-btn reset' onClick={AddNotes}>Add</button>}
                
                <button className='reset-btn reset' onClick={() => { setEditorState(initialEditorState); setReplyStatus(false); setNotesUpdateButton (false)}}>Cancel</button>
            </div>
        </div>
    )
}

export default InterviewNotes