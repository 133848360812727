import React, { useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Table from 'react-bootstrap/Table';
import { FaUserAlt } from "react-icons/fa";
import SearchProfilePage from './SearchProfileTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { postRequest } from '../Services/api';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { toast } from 'react-toastify';
import SideNavbar from '../Dashboard/SideNavbar';
import Footer from '../Dashboard/Footer';
import { useEffect } from 'react';

const SearchProfile = () => {
    const childRef = useRef();

    const searchModel = { EmailorPhone: "" }
    const [show, setShow] = useState(false);
    const [showNewCandidate, setShowNewCandidate] = useState(false);
    const [formValues, setFormValues] = useState(searchModel);
    const [formErrors, setFormErrors] = useState({});
    const [searchProfile, postSearchProfile] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setFormValues(formValues);
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0) {
            postRequest('api/Profile/SearchProfile', formValues).then((res) => {
                setLoading(false);
                if (res?.data?.Status) {
                    postSearchProfile(JSON.parse(res?.data?.Data));
                    setShow(true);
                    setShowNewCandidate(false);
                    childRef.current.getSearchProfileChild();
                }
                else {
                    setShow(false);
                
                    if (res?.data?.ErrorMessage == 'There is no record found') {
                        setShowNewCandidate(true);
                    }
                    if (res?.data?.ErrorMessage == 'There is no available points to search') {
                        toast.error('Search profile point is unavailable');
                    }
                }
            });
        } else {
            setShow(false);
            setLoading(false);
        }
    }
    // const [validNew, setValidNew] = useState(true)
    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

        if (!values.EmailorPhone) {
            errors.EmailorPhone = "Email / Mobile number is required";
            setShowNewCandidate(false);
        }

        if (!regex.test(values.EmailorPhone)) {
            if (!phone.test(values.EmailorPhone)) {
                errors.EmailorPhone = "Please enter valid Email / Mobile number";
                setShowNewCandidate(false);
            }
        }
        return errors;
    };

// useEffect(()=>{
//     setShowNewCandidate()
// },[showNewCandidate])
    return (
        <SideNavbar>
            <div>
                <Container fluid className='p-0 g-0' >
                    <Row className='g-0'>
                        <Col lg={6} xs={12} sm={12} className="mob-none">
                            <div className='breadcrumps-box'>
                                <div className='breadcrump-heading'>
                                    <p>Search Profile</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                            <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                <Typography className='text-white-breadcrums cus-mr-5' >Search Profile</Typography>

                            </Breadcrumbs>
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Container fluid className='px-4 py-3'>
                        <Row>
                            <Col lg={12}>

                                <div className='bg-white px-4 py-3'>
                                    <form onSubmit={handleSubmit}>
                                        <div className='search-profile-form'>

                                            <div className="did-floating-label-content mb-0 col-lg-8 col-sm-12" >
                                                <input className="did-floating-input" name='EmailorPhone' value={formValues.EmailorPhone} onChange={handleChange} maxLength="75" type="text" placeholder=" " />
                                                <div className='icon-background' > <FaUserAlt className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Email / Mobile number *</label>
                                                <p className="mb-1 form-error">{formErrors.EmailorPhone}</p>
                                            </div>
                                            <div className="search-reset-btn">
                                                <button className='search-btn' disabled={loading}>{loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null}
                                                    &nbsp; Search</button>
                                                <input type="reset" className='reset-btn' value="Reset" onClick={() => {
                                                    setFormValues(() => ""); setFormErrors({}); setShowNewCandidate(false);
                                                    setShow(false);
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                
                                {
                                    showNewCandidate && (
                                        <div className='bg-white px-4 py-3 text-center'>
                                           Searched profile is not available. Please click on the below button to send an offer to this candidate
                                            <br />  <br />
                                            <Link to="/shareprofile" >
                                                <button className='search-btn'    >Share profile</button> </Link>
                                        </div>
                                    )
                                }

                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    {show && (<Container fluid className='px-4 py-3'>
                        <Row className='g-0'>
                            <Col lg={12} className='bg-white'>
                                <div className='searched-table-heading px-2'>
                                    <h3>Employment Detail</h3>
                                </div>
                                <Table striped responsive borderless className='searched-table'>
                                    <thead>
                                        <tr>
                                            <th>Candidate Name</th>
                                            <th>Job Title</th>
                                            <th>Expected Start Date</th>
                                            <th>Experience</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            searchProfile.map((item) => (
                                                <tr>
                                                    <td>{item.CandidateName}</td>
                                                    <td>{item.JobTitle == null || item.JobTitle == "undefined" || item.JobTitle == "null"  ? "": item.JobTitle}</td>
                                                    <td>{item.JoinDate == null  ? null :
                                                        Moment(item.JoinDate).format('MM/DD/YYYY')}</td>
                                                    <td>{item.ExperienceValue}</td>
                                                    <td>{item.Status}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>)
                    }
                </div>
                <div>
                    <Container fluid>
                        <Row>
                            <Col>
                                <SearchProfilePage ref={childRef} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </SideNavbar>
    )
}

export default SearchProfile