import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
import { Pagination } from 'react-bootstrap'
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { postRequest } from '../Services/api';
import {FaEye} from 'react-icons/fa'
import Moment from 'moment';
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import PaginationComponent from './Pagination';
import Search from './Search';
import _ from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import ImageViewer from '../ModalPopup/ImageViewer'
import imgViewIcon from '../../assets/img/imgView.svg'

const SearchProfilePage = forwardRef((props, ref) => {
    const [searchProfileList, getSearchProfileList] = useState([]);
    const [show, setShow] = useState(false);
    const [emailOffered, setViewOffers] = useState([]);
    const [candidateName, setCandidateName] = useState([]);
    const [loading, setLoading] = useState(true)
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [selectItemsPerPage, setselectItemsPerPage] = useState(10);
    const [showImg, setShowImg] = useState(false);
    const [imgView, setImageView] = useState()
    const handleShowImg = async (profileId) => {
        setShowImg(true);
        setImageView("")
        await postRequest('api/Profile/GetDocumentView?ProfileId=' + profileId).then((res) => {
            setImageView('data:application/pdf;base64,' + JSON.parse(res?.data?.Data))
            setLoading(false);
        });

    }
    const handleCloseImg = () => setShowImg(false);

    const handleChangeItem = (e) => {
        setselectItemsPerPage(e.target.value);
        setCurrentPage(1);
    }
    useEffect(() => {
        setTotalItems(searchProfileList.length);
        // setFilterDataList(sharedProfileList);
    }, [searchProfileList])

    const handleShow = (email, candidateName) => {
        setShow(true);
        setViewOffers(email);
        setCandidateName(candidateName);
    }
    const handleClose = () => setShow(false);

    useEffect(() => {
        getSearchProfile();

    }, []);
    useImperativeHandle(ref, () => ({
        getSearchProfileChild() {
            getSearchProfile();
        },
    }));

    const getSearchProfile = async () => {
        setLoading(true);
        var res = await postRequest('api/Profile/GetSearchProfile');
        var result = JSON.parse(res?.data?.Data);
        result.map((item) => {
            item.SearchedDate = Moment(item.SearchedDate).format('MM/DD/YYYY HH:mm:ss')
        });
        getSearchProfileList(result);
        setLoading(false);


    }
    let filteredData = useMemo(() => {
        let filterData = searchProfileList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
        setTotalItems(filterData.length);
        return filterData
    }, [search, selectItemsPerPage, currentPage, searchProfileList]);

    useEffect(() => {
        // getSearchProfileList();
        setCurrentPage(1);
    }, []);
    //   const commentsData = useMemo(() => {
    //     let computedComments = searchProfileList;

    //     if (search) {

    //         computedComments = computedComments.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase()))) 

    //     }
    //     setTotalItems(computedComments.length); 
    //     //Current Page slice
    //     return computedComments;
    // }, [searchProfileList, currentPage, search]);
    const lastSessionNumber = currentPage * selectItemsPerPage;
    const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
    const limitedSessions = filteredData.slice(
        firstSessionIndex,
        lastSessionNumber
    );
    return (
        <div>
            <div >
                <Container fluid >
                    <Row>
                        <Col className='bg-white'>
                            <div className='searched-table-heading'>
                                <h3>Recently Searched Profile</h3>
                            </div>
                            <div className='search-filter-box-custom'>
                                <div className='searched-table-heading mt-3 mb-2'>
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="wrap-select-arrow mt-3 mb-2">
                                        <select className="form-control sorting-select " onChange={e => {
                                            setselectItemsPerPage(e.target.value);
                                            setCurrentPage(1);
                                        }}>
                                            <option value={10} selected="selected">10 Items per page</option>
                                            <option value={25}>25 Items per page</option>
                                            <option value={50}>50 Items per page</option>
                                            <option value={100}>100 Items per page</option>
                                        </select>
                                        <div className="select-arrow">
                                            <div className="arrow-up"></div>
                                            <div className="arrow-down"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>
                                        <th>Searched On</th>
                                        <th>Candidate name</th>
                                        <th className='text-center'>current offer</th>
                                        <th>mobile number</th>
                                        <th>email </th>
                                        <th>attachment</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ?
                                       <tr>
                                       <td class="text-center" colSpan={10}> <div class="positioning-spinner">
                                         <Spinner animation="border" role="status">
                                           <span className="visually-hidden text-center">Loading...</span>
                                         </Spinner>
                                       </div></td>
                                     </tr>: limitedSessions.length == 0 ?
                                            <tr>
                                                <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                            </tr> :
                                            limitedSessions.map((item, index) => (
                                                <tr>
                                                    <td>{Moment(item.SearchedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.TimeZone}</td>
                                                    <td> <Link to="/searchprofile" className='search-name-text-color' onClick={() => handleShow(item.Email + item.PhoneNumber, item.CandidateName)}>{item.CandidateName}</Link></td>
                                                    <td className='text-center'>{item.OfferCount}</td>
                                                    <td>{item.PhoneNumber}</td>
                                                    <td>{item.Email}</td>
                                                    <td className='text-center'><Link to="/searchprofile" className='search-name-text-color' onClick={() => handleShowImg(item.ProfileId)}>{item.ProfileDocument == ""?"":<img src={imgViewIcon} className='viewIcon-file' />}</Link> </td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </Table>

                            <div className='pagination-box'>
                                <div className='pagination-showing-count-status'>
                                   {limitedSessions.length == 0 ? "" :  <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems}</p>}
                                </div>
                                <div>
                                    <PaginationComponent
                                        itemsCount={totalItems}
                                        itemsPerPage={selectItemsPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        alwaysShown={false}

                                    />

                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalOfferDetails candidateName={candidateName} email={emailOffered} show={show} onClose={handleClose} />
            <ImageViewer imgView={imgView} showImg={showImg} onCloseImg={handleCloseImg} />
        </div>
    )
});

export default SearchProfilePage