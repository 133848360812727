
import './App.css';
import React, { useEffect, useState } from 'react';
import Signup from './Components/SignUp/Signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SideNavbar from './Components/Dashboard/SideNavbar';
import Dashboard from './Components/Dashboard/Dashboard';
import Chat from './Components/Chat'
import SearchProfile from './Components/Profiles/SearchProfile';
import SharedProfile from './Components/Profiles/SharedProfile';
import { postLogin } from './Components/Services/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { injectStyle } from "react-toastify/dist/inject-style";
import PrivateRoutes from './Components/Services/PrivateRoutes';
import MyProfile from './Components/UserSettings/MyProfile';
import Login from './Components/Login/Login';
import Page404 from './Components/Login/Page404';
import { useSelector } from 'react-redux';
import AdminDashboard from './Components/Dashboard/AdminDashboard';
import ForgotPassword from './Components/SignUp/ForgotPassword';
import OfferStatus from './Components/Profiles/OfferStatus';
import InviteCompany from './Components/Invite/InviteCompany';
import UserManagement from './Components/Profiles/UserManagement';
import TermsAndCondition from './Components/Login/TermsAndCondition';
import PrivacyPolicy from './Components/Login/PrivacyPolicy';
import ScrollToTop from './Components/Dashboard/ScrollToTop';
import ScheduleInterviews from './Components/Profiles/ScheduleInterviews ';
import Questionary from './Components/Profiles/Questionary';
import InterviewProfileview from './Components/Profiles/InterviewProfileview';
import Interview from './Components/Profiles/InterviewComponent/Interview'
import TestVideo from './Components/Profiles/InterviewComponent/TestVideo'
import { Outlet,Navigate,useLocation } from 'react-router-dom'
if (typeof window !== "undefined") {
  injectStyle();
}


function App() {
  
  let [authToken, setAuthToken] = useState(null);
  let [isLoggedIn, setisLoggedIn] = useState({});
  let tokens = useSelector((state) => state.userAuth.value);
  useEffect(() => {
    // tokens = tokens;
   let auth = JSON.parse(localStorage.getItem('token'))
   setisLoggedIn(auth)
  }, []);


  // console.log(process.env.PUBLIC_URL)

  return (
    <div >
      {/* <PrivateRoutes />  */}

      <Router >
        <ScrollToTop />
        <Routes>

          <Route element={(<PrivateRoutes />)} >

            <Route path='/' element={<Dashboard /> } />
            <Route path='/Dashboard' element={( <Dashboard />)} />
            <Route path='/searchprofile' element={( <SearchProfile /> )} />
            <Route path='/shareprofile' element={(<SharedProfile /> )} />  
            <Route path='/shareprofile/:id' element={( <SharedProfile /> )} />

            <Route path='/chat' element={(<Chat />)} />
            <Route path='/myprofile' element={(<MyProfile />)} />
            <Route path="/adminDashboard" element={<AdminDashboard />} />
            <Route path="/questionnaires" element={<Questionary />} />
            <Route path="/questionnaires/:id" element={<Questionary />} />
            <Route path='/schedule-interview' element={<ScheduleInterviews />} />
            <Route path='/schedule-interview/:id' element={<ScheduleInterviews />} />
            <Route path='/schedule-interview/interview-profile-view/:id' element={<InterviewProfileview />} />
            <Route path='/usermanagement' element={( <UserManagement /> )} />
           
            <Route path='/invite' element={( <InviteCompany />)}></Route>
            <Route path='*' element={<Page404 />} />
          </Route>

          {/* <Route path='/searchprofile' element={(<PrivateRoutes><SearchProfile /></PrivateRoutes>)} />
            <Route path='/shareprofile' element={(<PrivateRoutes><SharedProfile /></PrivateRoutes>)} />
            <Route path='/shareprofile/:id' element={(<PrivateRoutes><SharedProfile /></PrivateRoutes>)} />
            <Route path='/chat' element={(<PrivateRoutes><Chat /></PrivateRoutes>)} />
            <Route path='/myprofile' element={(<PrivateRoutes><MyProfile /></PrivateRoutes>)} />  */}

          <Route path='/login' element={(<Login />)} ></Route>
          <Route path='/signup' element={(<Signup />)} ></Route>
          <Route path='/offerstatus/:id' element={(<OfferStatus />)} ></Route>
          <Route path='/forgotpassword' element={(<ForgotPassword />)}></Route>
          <Route path='/terms-and-condition' element={(<TermsAndCondition />)}></Route>
          <Route path='/privacy-policy' element={(<PrivacyPolicy />)}></Route>
          <Route path='/interview/:id' element={(<Interview />)} />
          <Route path='/testvideo' element={(<TestVideo />)} />
        </Routes>

        {/* <Routes >        
        
        <Route path="/signup" element={(<Signup  />)} />
        
        </Routes> */}

      </Router>
      <ToastContainer />
    </div>
  );
}
export default App;
