import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
}


export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload;
    },
    clearToken: (state) => {
      state.value = [];
    }
   
  },
})


// Action creators are generated for each case reducer function
export const { setToken, clearToken } = userAuthSlice.actions



export default userAuthSlice.reducer