import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
// import { Col, Container, Row ,Alert} from 'reactstrap' 
import { Modal, Col, Container, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight, FaToggleOff, FaToggleOn } from 'react-icons/fa'
import { postRequest } from '../Services/api';
import { Pagination } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import Moment from 'moment';
import { FaEye, FaUserAlt, FaEnvelope, FaCalendarAlt, FaPenSquare } from "react-icons/fa";
import ModalOfferDetails from '../ModalPopup/ModalOfferDetails';
import PaginationComponent from '../Profiles/Pagination';
import Search from '../Profiles/Search';
import _ from "lodash"
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import SideNavbar from './SideNavbar';
import ReactTooltip from 'react-tooltip';
import Footer from './Footer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const AdminDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [selectItemsPerPage, setselectItemsPerPage] = useState(5);
    const [userList, getUserList] = useState([]);
    const [companyList, getcompanyList] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [candidateName, setCandidateName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [searchList, getSearchList] = useState([]);
    const [subUserList, getSubUserList] = useState([]);
    const [showSubUser, setShowSubUser] = useState(false);

    const [totalUserItems, setTotalUserItems] = useState(0);
    const [currentUserPage, setCurrentUserPage] = useState(1);
    const [searchUser, setSearchUser] = useState("");
    const [selectUserItemsPerPage, setselectUserItemsPerPage] = useState(5);

    const [dashboardList, getDashboardList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [monthShareList, setMonthShareList] = useState([]);
    const [organizationsCount, setOrganizationsCount] = useState([]);
    const [UserCountChart, setUserCountChart] = useState([]);
    const [PendingTotalCount, setPendingTotalCount] = useState(null);
    const [InterviewTotalCount, setInterviewTotalCount] = useState(null);
    const [OfferedTotalCount, setOfferedTotalCount] = useState(null);
    const [OnboardTotalCount, setOnboardTotalCount] = useState(null);
    const [RevokeTotalCount, setRevokeTotalCount] = useState(null);
    const [DeclinedTotalCount, setDeclinedTotalCount] = useState(null);
    const [shareTopCount, setShareTopCount] = useState([]);
    const [searchTopCount, setSearchTopCount] = useState([]);
    const [PendingCount, setPendingCount] = useState([]);
    const [RevokeCount, setRevokeCount] = useState([]);
    const [DeclinedCount, setDeclinedCount] = useState([]);
    const [InterviewCount, setInterviewCount] = useState([]);
    const [OfferedCount, setOfferedCount] = useState([]);
    const [OnboardCount, setOnboardCount] = useState([]);

    useEffect(() => {
        getDashboard();

    }, []);
    const getDashboard = async () => {
        await postRequest('/api/Verification/AdminDasboard').then((res) => {
            var result = JSON.parse(res?.data?.Data);
            getDashboardList(result);
            result.RegisterUser.map((item) => {
                item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
            });

            result.ActiveUser.map((item) => {
                item.CreatedDate = Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss')
            });
            getcompanyList(result.RegisterUser);
            getUserList(result.ActiveUser);
            setLoading(false);
        });
    }

    useEffect(() => {
        setTotalItems(companyList.length);
    }, [companyList])

    useEffect(() => {
        setTotalUserItems(userList.length);
    }, [userList])
    let item = {};
    let CompanyCount = 0;
    let UserCount = 0;
    let ActiveUserCount = 0;
    if (dashboardList.CountData) {
        CompanyCount = dashboardList.CountData.CompanyCount;
        UserCount = dashboardList.CountData.UserCount;
        ActiveUserCount = dashboardList.CountData.ActiveUserCount;
    }

    let filteredData = useMemo(() => {
        let filterData = companyList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(search.toLowerCase())));
        setTotalItems(filterData.length);
        return filterData
    }, [search, selectItemsPerPage, currentPage, companyList]);

    let filteredUserData = useMemo(() => {
        let filterData = userList?.filter((x) => Object.keys(x).some(y => String(x[y]).toLowerCase().includes(searchUser.toLowerCase())));
        setTotalUserItems(filterData.length);
        return filterData
    }, [searchUser, selectUserItemsPerPage, currentUserPage, userList]);


    useEffect(() => {
        // getSearchProfileList();
        setCurrentPage(1);
        setCurrentUserPage(1);

    }, []);


    const lastSessionNumber = currentPage * selectItemsPerPage;
    const firstSessionIndex = lastSessionNumber - selectItemsPerPage;
    const limitedSessions = filteredData.slice(
        firstSessionIndex,
        lastSessionNumber
    );

    const userlastSessionNumber = currentUserPage * selectUserItemsPerPage;
    const userfirstSessionIndex = userlastSessionNumber - selectUserItemsPerPage;
    const limitedUserSessions = filteredUserData.slice(
        userfirstSessionIndex,
        userlastSessionNumber
    );


    const handleActiveUser = async (userId, status) => {
        await postRequest('/api/Verification/UserStatusUpdate?userid=' + userId + '&IsActive=' + status).then((res) => {
            var result = JSON.parse(res?.data?.Data);
            if (res?.data.Status) {
                if (!status) {
                    toast.success('User deactivated successfully')
                }
                else {
                    toast.success('User activated successfully')
                }
                getDashboard();
            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    };

    const handleResetPassword = async (userId) => {
        await postRequest('/api/Authentication/AdminPasswordReset?userid=' + userId).then((res) => {
            if (res?.data.Status) {
                toast.success('User password resent mail send successfully');
            }
            else {
                toast.error(res?.data.ErrorMessage)
            }
        })
    };

    const handleShowUserDetail = async (userId, firstName, lastName, companyName) => {
        let first = firstName;
        let last = lastName == null ? "" : lastName
        // console.log(first + ' ' + last)

        setCandidateName(first + ' ' + last);
        setCompanyName(companyName);

        await postRequest('/api/Verification/GetUserDetail?userid=' + userId).then((res) => {
            var result = JSON.parse(res?.data?.Data);
            getSearchList(result);
            item = searchList[0];
            setShow(true);
            setShowSubUser(false);
        })
    };

    const handleShowSubUserDetail = async (userId, userName, companyName) => {
        setShowSubUser(false);
        await postRequest('/api/Profile/GetSubUser?userid=' + userId).then((res) => {
            var result = JSON.parse(res?.data?.Data);
            if (result != null) {
                getSubUserList(result)
                setCandidateName(userName);
                setCompanyName(companyName);
                setShow(true);
                setShowSubUser(true);
            }
            else {
                setShow(false);
                setShowSubUser(false);
                toast.error('There is no users found');
            }


        })
    };
    useEffect(() => {
        getChartData()
        getChartSuperAdminData()
    }, [])
    const getChartData = async () => {
        var res = await postRequest(`api/ScheduleInterview/AdminUserChart`);
        let data = JSON.parse(res?.data?.Data)

        let month = data?.map((item) => item?.Month?.slice(0, 3) + " " + item?.YEARS)
        setMonthShareList(month)
        // setOrganizationsCount(data.reduce((accumulator, object) => {
        //     return accumulator + object.PendingCount;
        // }, 0));

        setOrganizationsCount(data.map((item) => item?.Organizations))
        setUserCountChart(data.map((item) => item?.UserCount))


        // getDashboardList(JSON.parse(res?.data?.Data));
    }

    const getChartSuperAdminData = async () => {
        var res = await postRequest(`api/ScheduleInterview/AdminStatusChart`);
        let data = JSON.parse(res?.data?.Data)
        let month = data?.map((item) => item?.Month?.slice(0, 3) + " " + item?.YEARS)
        setMonthList(month)
        setPendingCount(data.map((item) => item?.PendingCount))
        setOnboardCount(data.map((item) => item?.OnboardCount))
        setRevokeCount(data.map((item) => item?.RevokeCount))
        setDeclinedCount(data.map((item) => item?.DeclinedCount))
        setOfferedCount(data.map((item) => item?.OfferedCount))
        setInterviewCount(data.map((item) => item?.InterviewCount))
        setPendingTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.PendingCount;
        }, 0));
        setInterviewTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.InterviewCount;
        }, 0));
        setOnboardTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.OnboardCount;
        }, 0));
        setOfferedTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.OfferedCount;
        }, 0));
        setRevokeTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.OfferedCount;
        }, 0));
        setDeclinedTotalCount(data.reduce((accumulator, object) => {
            return accumulator + object.OfferedCount;
        }, 0));
    }
    // const getShareChartData = async () => {
    //     var res = await postRequest(`api/ScheduleInterview/ShareCount`);
    //     let data = JSON.parse(res?.data?.Data)
    //     let month = data?.map((item) => item?.Month?.slice(0, 3) + " " + item?.YEARS)
    //     console.log(data)
    //     setMonthShareList(month)
    //     setSharedCountChart(data.map((item) => item?.SharedCount))



    //     // getDashboardList(JSON.parse(res?.data?.Data));
    // }

    const data = {
        labels: monthList,
        datasets: [
            {
                label: 'Pending',
                data: PendingCount,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },

            {
                label: 'Onboard',
                data: OnboardCount,
                backgroundColor: ' #feeecd',
                borderColor: '#FAC144',
                borderWidth: 1,
            },
            {
                label: 'Revoke',
                data: RevokeCount,
                backgroundColor: '#d1eafa',
                borderColor: '#5db4ef',
                borderWidth: 1,
            },
            {
                label: 'Declined',
                data: DeclinedCount,
                backgroundColor: '#f5bcbc',
                borderColor: ' #de2121',
                borderWidth: 1,
            },

            {
                label: 'Offered',
                data: OfferedCount,
                backgroundColor: '#27a2434d ',
                borderColor: ' #27A243',
                borderWidth: 1,
            },
            {
                label: 'Interview',
                data: InterviewCount,

                backgroundColor: ' #e0701a73',
                borderColor: '#E0701A',
                borderWidth: 1,
            },
        ],
    };
    const data2 = {
        labels: monthShareList,
        datasets: [
            {
                label: 'Organizations count',
                data: organizationsCount,
                backgroundColor: '#112b3c3d',
                borderColor: '#112B3C ',
                borderWidth: 1,
            },
            {
                label: 'User count ',
                data: UserCountChart,
                backgroundColor: ' #e0701a73',
                borderColor: '#E0701A',
                borderWidth: 1,
            },

        ],
    };
    const data1 = {
        labels: ['Pending ', 'Onboard ', 'Offered ', 'Interview ', 'Revoke', 'Declined'],
        datasets: [
            {
                label: 'Total count',
                data: [PendingTotalCount, OnboardTotalCount, OfferedTotalCount, InterviewTotalCount, RevokeTotalCount, DeclinedTotalCount],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    '#feeecd',
                    '#27a2434d',
                    '#e0701a73',
                    '#d1eafa',
                    '#f5bcbc',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    '#FAC144',
                    '#27A243',
                    '#E0701A',
                    '#5db4ef',
                    '#de2121',
                ],
                borderWidth: 1,
            },
        ],
    };
    const chartHeight = 400;
    const chartHeight1 = 400;
    const options2 = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            // tooltip: {
            //     callbacks: {
            //         label: (context) => {
            //             const label = context.label || '';
            //             const value = context.raw || 0;
            //             const dataset = context.dataset || {};
            //             const total = dataset.data.reduce((acc, val) => acc + val, 0);
            //             const percentage = ((value / total) * 100).toFixed(2);
            //             return `${label}: ${value} (${percentage}%)`;
            //         },
            //     },
            // },
            datalabels: {
                color: 'white',
                font: {
                    weight: 'bold',
                },
                formatter: (value, ctx) => {
                    const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
                    const total = dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${percentage}%`;
                },
            },
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
            },
        },
        height: chartHeight1,
    };
    const maxDataValue1 = Math.max(
        ...data2.datasets.map((dataset) => Math.max(...dataset.data))
      );
      const suggestedMaxValue1 = maxDataValue1 > 50 ? maxDataValue1 : 50;
    const options1 = {
        maintainAspectRatio: false, // Disable the default aspect ratio
        responsive: true, // Make the chart responsive
        plugins: {
          legend: {
            display: true, // Set to true or false based on your requirements
          },
          
        },
        scales: {
          y: {
            // min: 0, // Set the minimum value for the y-axis
            // max: , // Set the maximum value for the y-axis
            suggestedMax: maxDataValue1,
            stepSize: maxDataValue1 > 50 ? undefined : 5,
            max: maxDataValue1 > 50 ? undefined : 50,
          },
        },
        layout: {
          padding: {
            top: 20,
            bottom: 20,
            left: 20,
            right: 20,
          },
        },
        // Set the height of the chart
        // The height value should be a number, not a string
        // You can adjust the chartHeight variable as needed
        height: chartHeight,
      };
    // const chartHeight = 400;
    // const chartHeight1 = 400;
    // const options2 = {
    //     maintainAspectRatio: false, // Disable the default aspect ratio
    //     responsive: true, // Make the chart responsive
    //     plugins: {
    //         legend: {
    //             display: true, // Set to true or false based on your requirements
    //         },
    //     },
    //     // scales: {
    //     //   y: {
    //     //     beginAtZero: true,
    //     //   },
    //     // },
    //     layout: {
    //         padding: {
    //             top: 10,
    //             bottom: 10,
    //             left: 10,
    //             right: 10,
    //         },
    //     },
    //     // Set the height of the chart
    //     // The height value should be a number, not a string
    //     // You can adjust the chartHeight variable as needed
    //     height: chartHeight1,
    // };

    // const options = {
    //     maintainAspectRatio: false, // Disable the default aspect ratio
    //     responsive: true, // Make the chart responsive
    //     plugins: {
    //         legend: {
    //             display: true, // Set to true or false based on your requirements
    //         },
    //     },
    //     scales: {
    //         y: {
    //             beginAtZero: true,
    //         },
    //     },
    //     layout: {
    //         padding: {
    //             top: 20,
    //             bottom: 20,
    //             left: 20,
    //             right: 20,
    //         },
    //     },
    //     // Set the height of the chart
    //     // The height value should be a number, not a string
    //     // You can adjust the chartHeight variable as needed
    //     height: chartHeight,
    // };
    const maxDataValue = Math.max(
        ...data.datasets.map((dataset) => Math.max(...dataset.data))
    );
    const suggestedMaxValue = maxDataValue > 50 ? maxDataValue : 50;

    const options = {
        maintainAspectRatio: false, // Disable the default aspect ratio
        responsive: true, // Make the chart responsive
        plugins: {
            legend: {
                display: true, // Set to true or false based on your requirements
            },
        },
        scales: {
            y: {
                // // min: 0, // Set the minimum value for the y-axis
                // // max: , // Set the maximum value for the y-axis
                suggestedMax: suggestedMaxValue,
                stepSize: suggestedMaxValue > 50 ? undefined : 5,
                max: suggestedMaxValue > 50 ? undefined : 50,
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 20,
                left: 20,
                right: 20,
            },
        },
        // Set the height of the chart
        // The height value should be a number, not a string
        // You can adjust the chartHeight variable as needed
        height: chartHeight,
    };
    const getTopShareCount = async () => {
        await postRequest('api/ScheduleInterview/GetAdminShareCount').then((res) => {
            setShareTopCount(JSON.parse(res?.data?.Data))
        })
    }
    const getTopSearchCount = async () => {
        await postRequest('api/ScheduleInterview/GetAdminSearchCount').then((res) => {
            setSearchTopCount(JSON.parse(res?.data?.Data))
        })
    }
    useEffect(() => {
        getTopShareCount()
        getTopSearchCount()
    }, [])
    return (
        <div className='bg-dashboard'>
            <Container fluid className='p-0 g-0' >
                <Row className='g-0'>
                    <Col lg={6} xs={12} sm={12} className="mob-none">
                        <div className='breadcrumps-box'>
                            <div className='breadcrump-heading'>
                                <p>Dashboard</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                        <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                            {/* <Link to='/' className='breadcrumps-links breadcrumps-links'>Home</Link> */}
                            <Typography className='text-white-breadcrums cus-mr-5' >Dashboard</Typography>

                        </Breadcrumbs>
                    </Col>
                </Row>
            </Container>
            <div>
                <Container fluid className='p-0'>
                    <Row>
                        <Col lg={12}>
                            <div>
                                <Container fluid className='px-4 py-2'>
                                    <Row className='g-lg-5 g-md-2 g-3'>
                                        <Col md={12} lg={4} sm={12}>
                                            <div className=' count-status-admin '>
                                                <div className='available-count-heading'>
                                                    <h4>Registered Organizations</h4>

                                                    <p>Total organizations registered</p>
                                                </div>
                                                <div className='available-count-status'>
                                                    <h1>{companyList.length} </h1>
                                                </div>

                                            </div>

                                        </Col>
                                        <Col md={12} lg={4} sm={12}>
                                            <div className=' count-status-admin '>
                                                <div className='available-count-heading'>
                                                    <h4>Registered Users</h4>
                                                    <p>Total users registered</p>
                                                </div>
                                                <div className='available-count-status'>
                                                    <h1> {UserCount}</h1>
                                                </div>

                                            </div>

                                        </Col>
                                        <Col md={12} lg={4} sm={12}>
                                            <div className=' count-status-admin '>
                                                <div className='available-count-heading'>

                                                    <h4>Active Users</h4>

                                                    <p>Total users in active state</p>
                                                </div>
                                                <div className='available-count-status'>
                                                    <h1>{ActiveUserCount} </h1>
                                                </div>

                                            </div>

                                        </Col>


                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Container fluid className='mt-2 py-3'>
                    <Row>
                        <Col lg={12} sm={12} className='h-50'>
                            <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>
                                <Bar data={data} options={options} />
                            </div>

                        </Col>
                        <Col lg={6} sm={12} className='mt-4'>
                            <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>

                                <Pie data={data1} options={options2} />
                            </div>
                        </Col>
                        <Col lg={6} className='h-50 mt-4' >
                            {/* <Bar /> */}
                            <div className='py-2 status-chart-heading' style={{ height: "400px", backgroundColor: "#fff" }}>

                                <Line data={data2} options={options1} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container fluid className='px-3 py-4'>
                    <Row>
                        <Col>
                            <div className='px-3'>
                                <div className='border border-1 share-count-heading-top bg-white'>
                                    <h4 className='most-shared-profile-heading'>Most profile shared organizations</h4>
                                    <div className='px-2'>
                                        <Table striped responsive borderless className='searched-table border border-1 '>
                                            <thead>
                                                <tr>
                                                    <th>Organization name</th>
                                                    <th>Count of share profile</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    shareTopCount.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{item.CompanyName}</td>
                                                                    <td className='text-center'>{item.SharedCount}</td>
                                                                </tr>
                                                            </>

                                                        )

                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='px-3 '>
                                <div className='border border-1 share-count-heading-top bg-white'>
                                    <h4 className='most-shared-profile-heading'>Most profile search organizations</h4>
                                    <div className='px-2'>
                                        <Table striped responsive borderless className='searched-table border border-1 '>
                                            <thead>
                                                <tr>
                                                    <th>Organization name</th>
                                                    <th>Count of search profile</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    searchTopCount.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{item.CompanyName}</td>
                                                                    <td className='text-center'>{item.SearchCount}</td>
                                                                </tr>
                                                            </>

                                                        )

                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container fluid className='px-4' >
                    <Row className='px-2'>
                        <Col className='bg-white'>
                            <div className='searched-table-heading'>
                                <h3 className='p-1 m-0 mt-2'>Registered Organizations</h3>
                            </div>

                            <div className='search-filter-box-custom'>
                                <div className='searched-table-heading mt-3 mb-2'>
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="wrap-select-arrow mt-3 mb-2">
                                        <select className="form-control sorting-select " onChange={e => {
                                            setselectItemsPerPage(e.target.value);
                                            setCurrentPage(1);
                                        }}>
                                            <option value={5} selected="selected">5 Items per page</option>
                                            <option value={10} >10 Items per page</option>
                                            <option value={25}>25 Items per page</option>
                                            <option value={50}>50 Items per page</option>
                                            <option value={100}>100 Items per page</option>
                                        </select>
                                        <div className="select-arrow">
                                            <div className="arrow-up"></div>
                                            <div className="arrow-down"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>
                                        <th width="20%">Organization name</th>
                                        <th width="20%">Contact Name</th>
                                        <th width="20%">mobile number</th>
                                        <th width="20%">email </th>
                                        <th width="20%">Registered On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ?
                                        <tr>
                                            <td colSpan={5}> <div class="positioning-spinner text-center">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div></td>
                                        </tr> : limitedSessions.length == 0 ?
                                            <tr>
                                                <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                            </tr> :
                                            limitedSessions.map((item) => {
                                                let firstName = item.FirstName == null ? "" : item.FirstName
                                                let lastName = item.LastName == null ? " " : item.LastName

                                                return (
                                                    <tr>
                                                        <td>{item.CompanyName} </td>
                                                        <td>{firstName + " " + lastName}</td>
                                                        <td>{item.PhoneNumber}</td>
                                                        <td>{item.Email}</td>
                                                        <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.Timezone}</td>
                                                    </tr>
                                                )
                                            })
                                    }

                                </tbody>
                            </Table>

                            <div className='pagination-box'>
                                <div className='pagination-showing-count-status'>
                                    {limitedSessions.length == 0 ? "" : <p>  Results: {currentPage == 1 ? totalItems > 0 ? 1 : 0 : totalItems > 0 ? ((currentPage - 1) * selectItemsPerPage) + 1 : 0} - {(currentPage - 1) == 0 ? totalItems < selectItemsPerPage ? totalItems : selectItemsPerPage : ((currentPage) * selectItemsPerPage) > totalItems ? totalItems : (currentPage) * selectItemsPerPage}  of {totalItems} </p>}
                                </div>
                                <div>
                                    <PaginationComponent
                                        itemsCount={totalItems}
                                        itemsPerPage={selectItemsPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        alwaysShown={false}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container fluid className='mt-4 px-4' >
                    <Row className='px-2'>
                        <Col className='bg-white'>
                            <div className='searched-table-heading'>
                                <h3 className='p-1 m-0 mt-2'>Registered Users</h3>
                            </div>

                            <div className='search-filter-box-custom'>
                                <div className='searched-table-heading mt-3 mb-2'>
                                    <Search
                                        onSearch={value => {
                                            setSearchUser(value);
                                            setCurrentUserPage(1);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="wrap-select-arrow mt-3 mb-2">
                                        <select className="form-control sorting-select " onChange={e => {
                                            setselectUserItemsPerPage(e.target.value);
                                            setCurrentUserPage(1);
                                        }}>
                                            <option value={5} selected="selected">5 Items per page</option>
                                            <option value={10} >10 Items per page</option>
                                            <option value={25}>25 Items per page</option>
                                            <option value={50}>50 Items per page</option>
                                            <option value={100}>100 Items per page</option>
                                        </select>
                                        <div className="select-arrow">
                                            <div className="arrow-up"></div>
                                            <div className="arrow-down"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <ReactTooltip id='sadFace' type="dark" effect='solid'>
                                    <span>Show sad face</span>
                                </ReactTooltip> */}

                            <a >
                            </a>
                            {/* <ReactTooltip id="sadFace" type="warning" effect="solid">
                                        <span>Show sad face</span>
                                    </ReactTooltip> */}



                            <Table striped responsive borderless className='searched-table'>
                                <thead>
                                    <tr>
                                        <th width="20%">Contact Name</th>
                                        <th width="20%">Organization name</th>
                                        <th width="10%">mobile number</th>
                                        <th width="20%">email </th>
                                        <th width="15%">Registered On</th>
                                        <th width="15%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan={6}> <div class="positioning-spinner text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div></td>
                                            </tr> : limitedUserSessions.length == 0 ?
                                                <tr>
                                                    <td class="text-center" colSpan={10}><h6>No Data Available!</h6></td>
                                                </tr> :

                                                limitedUserSessions.map((item) => {
                                                    let firstName = item.FirstName == null ? "" : item.FirstName
                                                    let lastName = item.LastName == null ? " " : item.LastName
                                                    return (
                                                        <tr>
                                                            <td>{firstName + " " + lastName}</td>
                                                            <td>{item.CompanyName} </td>
                                                            <td>{item.PhoneNumber}</td>
                                                            <td data-tip data-for='sadFace'> {item.Email}</td>
                                                            <td>{Moment(item.CreatedDate).format('MM/DD/YYYY HH:mm:ss') + ' ' + item.Timezone}</td>
                                                            <td >
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={item.Status === "True" ? <Tooltip id="tooltip1">Active</Tooltip> : <Tooltip id="tooltip2">Deactivate</Tooltip>}
                                                                >
                                                                    <span> {
                                                                        item.Status === "True" ?
                                                                            <FaToggleOn onClick={() => handleActiveUser(item.UserId, false)} className='mx-2' style={{ cursor: "pointer", fontSize: "large", color: "#e0701a" }} ></FaToggleOn>
                                                                            : <FaToggleOff onClick={() => handleActiveUser(item.UserId, true)} className='mx-2' style={{ cursor: "pointer", fontSize: "large", color: "gray" }} />
                                                                    }</span>
                                                                </OverlayTrigger>

                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip4">Resend Password</Tooltip>}
                                                                ><span> {<FaEnvelope onClick={() => handleResetPassword(item.UserId)} className='mx-2' style={{ cursor: "pointer" }} />}</span>
                                                                </OverlayTrigger>

                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip3">View</Tooltip>}
                                                                ><span>{<FaEye onClick={() => handleShowUserDetail(item.UserId, item.FirstName, item.LastName, item.CompanyName)} className='mx-2' style={{ cursor: "pointer" }} />}</span>
                                                                </OverlayTrigger>

                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip3">Users</Tooltip>}
                                                                ><span> {
                                                                    item.RoleId == "2" && item.SubuserCount != "0" ? <FaUserAlt onClick={() => handleShowSubUserDetail(item.UserId, item.FirstName + ' ' + item.LastName, item.CompanyName)} className='mx-2' style={{ cursor: "pointer" }} /> : null
                                                                }</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                    }

                                </tbody>

                            </Table>

                            <div className='pagination-box'>
                                <div className='pagination-showing-count-status'>
                                    {limitedUserSessions.length == 0 ? " " : <p>  Results: {currentUserPage == 1 ? totalUserItems > 0 ? 1 : 0 : totalUserItems > 0 ? ((currentUserPage - 1) * selectUserItemsPerPage) + 1 : 0} - {(currentUserPage - 1) == 0 ? totalUserItems < selectUserItemsPerPage ? totalUserItems : selectUserItemsPerPage : ((currentUserPage) * selectUserItemsPerPage) > totalUserItems ? totalUserItems : (currentUserPage) * selectUserItemsPerPage}  of {totalUserItems} </p>}
                                </div>
                                <div>
                                    <PaginationComponent
                                        itemsCount={totalUserItems}
                                        itemsPerPage={selectUserItemsPerPage}
                                        currentPage={currentUserPage}
                                        setCurrentPage={setCurrentUserPage}
                                        alwaysShown={false}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div>

                <Modal style={{ zIndex: "999999" }} show={show} onHide={handleClose}
                    //   onHide={onClose} 
                    size="xl"
                    dialogClassName="modal-100w"
                    centered
                    aria-labelledby="example-custom-modal-styling-title-lg">
                    <Modal.Header closeButton className="bg-theme">
                        <Modal.Title id="example-custom-modal-styling-title-lg" className='modal-heading'>
                            {companyName} information
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {subUserList.length < 0 ?
                            <Table responsive borderless className='searched-table'>
                                <tbody>
                                    <tr >
                                        <td style={{ "fontWeight": "500",textAlign:"center" }}>No Data Available </td>
                                        
                                    </tr>
                                </tbody>
                            </Table> : */}
                        <div>{
                            showSubUser == true ?
                                <Row>
                                    {
                                        subUserList?.map((item, index) => (
                                            <Col className="col-md-12 col-lg-6 col-sm-12">
                                                {
                                                    (<div> <span style={{ "fontWeight": "700" }}>Sub User - {index + 1}</span></div>)
                                                }

                                                <Table responsive borderless className='searched-table' style={{ "border": "1px solid black" }}>
                                                    <tbody>
                                                        <tr key={index}>
                                                            <td style={{ "fontWeight": "500" }}>Contact Name</td>
                                                            <td>:</td>
                                                            <td>{item.Name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Mobile Number</td>
                                                            <td>:</td>
                                                            <td>{item?.PhoneNumber == null ? "" : item?.PhoneNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Email</td>
                                                            <td>:</td>
                                                            <td>{item.Email == null ? "" : item.Email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Job Title</td>
                                                            <td>:</td>
                                                            <td>{item?.JobTitle == null ? "" : item?.JobTitle}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>City</td>
                                                            <td>:</td>
                                                            <td>{item?.City == null ? "" : item?.City}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>State</td>
                                                            <td>:</td>
                                                            <td>{item?.State == null ? "" : item?.State}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Country</td>
                                                            <td>:</td>
                                                            <td>{item?.CountryName == null ? "" : item?.CountryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Zip code</td>
                                                            <td>:</td>
                                                            <td>{item?.PostalCode == null ? "" : item?.PostalCode}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Time Zone</td>
                                                            <td>:</td>
                                                            <td>{item?.TimezoneValue == null ? "" : item?.TimezoneValue}</td>
                                                        </tr>


                                                        <tr>
                                                            <td style={{ "fontWeight": "500" }}>Status</td>
                                                            <td>:</td>
                                                            <td>{item?.Status == true ? "Active" : "Deactive"}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                :
                                show == true ?
                                    <Row>
                                        <Col md={12} sm={12} ></Col>
                                        <Col className="col-md-6  col-xs-12" md={12} xs={12} sm={12} lg={6} style={{ "border": "1px solid black" }}>

                                            <Table responsive borderless className='searched-table'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Organization Name</td>
                                                        <td>:</td>
                                                        <td>{companyName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Contact Name</td>
                                                        <td>:</td>
                                                        <td>{candidateName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Mobile Number</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.PhoneNumber == null ? "" : searchList[0]?.PhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Email</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.Email == null ? "" : searchList[0]?.Email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Job Title</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.JobTitle == null ? "" : searchList[0]?.JobTitle}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>City</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.City == null ? "" : searchList[0]?.City}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>State</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.State == null ? "" : searchList[0]?.State}</td>
                                                    </tr>


                                                </tbody>
                                                {/* </Table>
                                        </Col>                                        
                                        <Col className="Col-md-5 col-sm-12" style={{"border": "1px solid black","borderLeft": "0px"}}>
                                        <Table responsive borderless className='searched-table'>                                          */}
                                                <tbody>



                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Country</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.Country == null ? "" : searchList[0]?.Country}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Zip code</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.PostalCode == null ? "" : searchList[0]?.PostalCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Time Zone</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.TimeZoneValue == null ? "" : searchList[0]?.TimeZoneValue}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ "fontWeight": "500" }}>Status</td>
                                                        <td>:</td>
                                                        <td>{searchList[0]?.Status == true ? "Active" : "Deactive"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>

                                        <Col md={12} sm={12}></Col>
                                    </Row>
                                    : <h1>sdf</h1>
                        }

                        </div>
                        {/* } */}

                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </div>
    )

}
export default AdminDashboard