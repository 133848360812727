import React, { useEffect, useState } from 'react'
import NotesImg from '../../../assets/img/userimg.png'
import { postRequest } from '../../Services/api';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RecentNotes = ({ profileId, recentNotes, NotesEdit, DeleteNotes, ReplyNotes, UserId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [notesPerPage, setNotesPerPage] = useState(3);
    // console.log(recentNotes)
    const indexOfLastNote = currentPage * notesPerPage;
    const indexOfFirstNote = indexOfLastNote - notesPerPage;
    const currentNotes = recentNotes?.slice(indexOfFirstNote, indexOfLastNote);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(recentNotes.length / notesPerPage); i++) {
        pageNumbers.push(i);
    }
    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const maxPageNumbersToShow = 5;
    const halfOfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let pageNumbersToShow = [];

    if (pageNumbers.length <= maxPageNumbersToShow) {
        pageNumbersToShow = pageNumbers;
    } else if (currentPage <= halfOfMaxPageNumbersToShow) {
        pageNumbersToShow = [...pageNumbers.slice(0, maxPageNumbersToShow - 1), '...', pageNumbers[pageNumbers.length - 1]];
    } else if (currentPage >= pageNumbers.length - halfOfMaxPageNumbersToShow) {
        pageNumbersToShow = [pageNumbers[0], '...', ...pageNumbers.slice(pageNumbers.length - (maxPageNumbersToShow - 1), pageNumbers.length)];
    } else {
        pageNumbersToShow = [pageNumbers[0], '...', ...pageNumbers.slice(currentPage - halfOfMaxPageNumbersToShow + 1, currentPage + halfOfMaxPageNumbersToShow - 1), '...', pageNumbers[pageNumbers.length - 1]];
    }
    // console.log(currentNotes)
    return (
        <div>
            {currentNotes?.map((item) => {
                const htmlContent = item?.Notes == "null" || item?.Notes == "undefined" ? "" : item.Notes;
                const contentState = ContentState.createFromBlockArray(convertFromHTML(htmlContent));
                const itemEditorState = EditorState.createWithContent(contentState);

                return (
                    <div className='d-flex mt-4' key={item.Id}>
                        <div>
                            <img src={NotesImg} className="profile-img-navbar" />
                        </div>
                        <div className='notes-box-align'>
                            <div className='py-1 px-2 notes-updated-times-align'>
                                <h4 className='text-capitalize'>
                                    {item.FirstName} {item.LastName}
                                    <span className='py-1 px-2'>{item.TIME}</span>
                                </h4>
                            </div>
                            <div className='py-1 px-1 mb-2 notes-text-styles mt-editor-movetop'>
                                <Editor
                                    className=""
                                    editorState={itemEditorState}
                                    toolbarHidden
                                    readOnly
                                />
                            </div>
                            <div className='notes-action--box-align   px-2'>
                                {/* <p onClick={ReplyNotes}>
                                    Reply
                                </p> */}
                                {item?.CreatedBy == UserId ? <span className='edit-delete-aling'><p onClick={() => NotesEdit(item?.InterviewNotesId)}>
                                    Edit
                                </p>
                                    <p onClick={() => { DeleteNotes(item?.InterviewNotesId,item?.CompanyId,item?.CreatedBy) }}>
                                        Delete
                                    </p></span> : ""}

                            </div>
                        </div>
                    </div>
                );
            })}
            {recentNotes.length > 0 &&
                <div className="pagination">
                    <button className="pagination-button" onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1}>
                        {'<'}
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <React.Fragment key={index}>
                            {pageNumber === '...' ? (
                                <div className="pagination-ellipsis">{pageNumber}</div>
                            ) : (
                                <button className={`pagination-button ${currentPage === pageNumber ? 'active-notes' : ''}`} onClick={() => handlePaginationClick(pageNumber)}>
                                    {pageNumber}
                                </button>
                            )}
                        </React.Fragment>
                    ))}
                    <button className="pagination-button" onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === pageNumbers.length}>
                        {'>'}
                    </button>
                </div>
            }

        </div>
    );
}

export default RecentNotes;
