import axios from "axios";
// export const BASE_URL = 'https://gateway.knila.com/projectr_api/';
// export const BASE_URL = 'http://72.249.77.205:8082/';
export const BASE_URL = 'https://dev.knila.com:8016/';


let result = JSON.parse(localStorage.getItem('token'));
let Authtoken = result?.token;

let headerParam = {
  "Authorization": `Bearer ` + Authtoken,
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "withCredentials": false,
};

export const BLOG_LIST = 'api/Master/GetJobProfile';

let axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: headerParam,
});
let token = '';
export const USERID = 1;
export const postRequest = async (url, body) => {
  try {
    const res = await (await axiosInstance.post(url, body));
    return res;
  } catch (e) {
    return { success: false, message: e.message, body: {} };
  }
};
export const postRequestOffer = async (url, body) => {
  try {
    const res = await (await axiosInstance.get(url, body));
    return res;
  } catch (e) {
    return { success: false, message: e.message, body: {} };
  }
};

export const postLogin = async (url, body) => {
  try {
    // let url='api/Authentication/Login';
    // let body = {
    //   email: "purusothaman.r@knila.com",
    //   Password: "Knila@123"
    // }
    const res = await axiosInstance.post(url, body);

    let result = JSON.parse(JSON.stringify(res.data?.data));
    token = result.token;
    headerParam = {
      "Authorization": `Bearer ` + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: headerParam,
    });

    return res;
  } catch (e) {
    return { success: false, message: e.message, body: {} };
  }
};

export const postVerify = async (url, body) => {
  try {
    // let url='api/Authentication/Login';
    // let body = {
    //   email: "purusothaman.r@knila.com",
    //   Password: "Knila@123"
    // }
    const res = await axiosInstance.post(url, body);

    // let result = JSON.parse(JSON.stringify(res.data?.data));
    // token=result.token;
    // headerParam = { 
    //   "Authorization": `Bearer ` + token,
    //   Accept: "application/json",
    //   "Content-Type": "application/json", 
    // };

    axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: headerParam,
    });

    return res;
  } catch (e) {
    console.log(e)
    return { success: false, message: e.message, body: {} };
  }
};
export const postFormData = async (url, formData) => {
  try {
    let result = JSON.parse(localStorage.getItem('token'));
    let AuthtokenformData = result?.token;
    // console.log(AuthtokenformData)
    const res = await axios.post(BASE_URL + url, formData, {
      headers: {
        "Content-type": "multipart/form-data", "Authorization": `Bearer ` + AuthtokenformData,
      },
    })
    return res;
  } catch (e) {
    return { success: false, message: e.message, body: {} };
  }
}; 