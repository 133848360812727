import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { FaAddressCard, FaUserAlt, FaPhoneAlt, FaEnvelope, FaCalendarAlt, FaPenSquare, FaRegCheckCircle, FaRegCommentAlt } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1Wave, faSackDollar, faCheckToSlot, faBriefcase, faClipboardList, faRectangleList } from '@fortawesome/free-solid-svg-icons'
import SharedProfileTable from '../Profiles/SharedProfileTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FaChevronRight } from 'react-icons/fa'
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import { postRequest, postFormData } from '../Services/api';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { result } from 'lodash';
import SideNavbar from '../Dashboard/SideNavbar';
import Footer from '../Dashboard/Footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import InterviewListTable from './InterviewListTable';
import QuestionaryTable from './QuestionaryTable';
import './Questionary.css'
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Questionary = () => {
    const childRef = useRef();
    const location = useLocation()
    const path = location.pathname.split('/');
    let id = parseInt(path[2]);
    const navigate = useNavigate();

    useEffect(() => {
        getMyProfile()
        setErrorCategory("")
    }, [id])
    const [questionUpdate, setQuestionUpdate] = useState([])
    const getMyProfile = async () => {
        setData(() => "")

        setFormErrors({});
        var res = await postRequest(`api/Interview/GetQuestionById?QuestionId=${id}`);

        let result = JSON.parse(res?.data?.Data)[0];
        // console.log(result?.EmployementType);
        setQuestionUpdate(result)
        setData({
            Questions: result.Questions,

        })
        setValue(result?.CategoryName)

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const init = {
        Questions: "",

    }
    const [formData, setData] = useState(init);
    const [formErrors, setFormErrors] = useState({});
    let [errorCategory, setErrorCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [shareProfileupdate, setShareProfileUpdate] = useState();
    const [categoryReload, setCategoryReload] = useState(false)
    const [tableReload, settableReload] = useState(false)
    const handleUpdate = (event) => {
        event.preventDefault();
        setFormErrors(validate(formData));
        setLoading(true);
        
        if (value == "") {
            setErrorCategory("Skill category is required")
        }
        if (Object.keys(validate(formData)).length === 0 && value !== "") {
            const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
            let data = {
                "QuestionId": questionUpdate?.QuestionId,
                "CategoryId": questionUpdate?.CategoryId,
                "CategoryName": capitalizedValue,
                "Questions": formData?.Questions,
                "CompanyId": isLoggedIn?.CompanyId,
            }
           
            postRequest('api/Interview/UpdateQuestionWithCategory', data).then((res) => {
                setLoading(false);
                if (!res?.data?.Status) {
                    if (res?.data?.ErrorMessage !== '') {
                        toast.error(res?.data?.ErrorMessage);

                    }
                    else {
                        toast.error(res?.data?.ErrorMessage ? res?.data?.ErrorMessage : res?.data?.message);
                    }
                }
                else {
                    toast.success(res?.data?.SuccessMessage);
                    childRef.current.getQuestionsChild();
                    navigate('/questionnaires')
                    setValue("")
                    setData(init)
                    setErrorCategory("")
                    
                    
                    
                }
            });

        }
        else {
            setLoading(false);
        }
    }

    const handleChange = (event, values) => {
        const { name, value } = event.target;
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setData(prevForm => {
            return {
                ...prevForm,
                [name]: capitalizedValue
            };
        });
    };




    // const initialvalueVal = {
    //     FirstName: "",
    //     LastName: "",
    //     PhoneNumber: "",
    //     Email: "",
    //     JobTitle: "",
    //     currency: "",
    //     SalaryId: "",
    //     ExperienceId: "",
    //     JoinDate: "",
    //     Status: "Pending",
    //     EmployementType: "",
    //     ProfileDocument: "",
    //     Notes: ""
    // }
    let isLoggedIn = JSON.parse(localStorage.getItem('token'))

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormErrors(validate(formData));
        setLoading(true);
        if(value == ""){
            setErrorCategory("Skill category is required")
        }
        if (Object.keys(validate(formData)).length === 0 && value !== "") {
            const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
            let data = {
                CategoryName: capitalizedValue,
                Questions: formData?.Questions,
                CompanyId: isLoggedIn?.CompanyId
            }
            // data.append('CategoryName', value)
            // data.append('Questions', formData?.Questions)
            // data.append('CompanyId', isLoggedIn?.CompanyId)

            postRequest('api/Interview/InsertQuestionWithCategory', data).then((res) => {
                setLoading(false);
                if (!res?.data?.Status) {
                    if (res?.data?.ErrorMessage !== '') {
                        toast.error(res?.data?.ErrorMessage);
                    }
                    else {
                        toast.error(res?.data?.ErrorMessage);
                    }
                }
                else {
                    toast.success(res?.data?.SuccessMessage);
                    setValue("")
                    setErrorCategory("")
                    setData(init)
                    setFormErrors({});
                    setCategoryReload(true)
                    settableReload(true)
                    childRef.current.getQuestionsChild();
                    navigate('/questionnaires')
                }
            });

        }
        else {
            setLoading(false);
        }



    }
    const validate = (values) => {
        const errors = {};


        if (!values.Questions) {
            errors.Questions = "Question is required";
        }



        return errors;
    };

    useEffect(()=>{
        getCategory()
        setCategoryReload(false)
    }, [categoryReload])





    const handleCancel = () => {
        navigate('/questionnaires')
    }
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }


    const [categoryData, setCategoryData] = useState([]);
    const inputRef = useRef(null);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const autocompleteNode = document.getElementById('autocomplete');

            if (autocompleteNode && !autocompleteNode.contains(event.target)) {
                setSuggestions([]);
                setShowDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const handleChangeSelect = (event) => {
        setValue(event.target.value);
        setSuggestions(
            categoryData?.filter((option) =>
                option?.CategoryName?.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
            )
        );
        setShowDropdown(true);
    };
    const handleSuggestionClick = suggestion => {
        setValue(suggestion.CategoryName);
        setSuggestions([]);
    };

    useEffect(() => {
        getCategory()
    }, [])
    const getCategory = async () => {
        var res = await postRequest(`api/Interview/GetCompanyById?CompanyId=${isLoggedIn?.CompanyId}`);
        setCategoryData(JSON.parse(res?.data?.Data))
    }
    // console.log(categoryData);
    return (
        <div>
            <SideNavbar>
                <div>
                    <Container fluid className='p-0 g-0' >
                        <Row className='g-0'>
                            <Col lg={6} xs={12} sm={12} className="mob-none">
                                <div className='breadcrumps-box'>
                                    <div className='breadcrump-heading'>
                                        <p>{!id ? "Questionnaires" : "Questionnaires"}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} xs={12} sm={12} className='breadcrumps-box  d-flex justify-content-end '>
                                <Breadcrumbs separator={<FaChevronRight className='text-white-breadcrums ' style={{ fontSize: "15px" }} />} >
                                    <Link to='/' className='breadcrumps-links breadcrumps-links'>Dashboard</Link>
                                    <Typography className='text-white-breadcrums cus-mr-5' >{!id ? "Questionnaires" : "Questionnaires"}</Typography>
                                </Breadcrumbs>
                            </Col>
                        </Row>
                    </Container>
                    <div>
                        <Container fluid className='p-4'>
                            <Row>
                                <Col lg={12}>

                                    <div className='bg-white p-4'>
                                        <form autocomplete="off" >
                                            <div className='search-profile-form'>

                                                {/* <div class="col-lg-11 col-sm-12 mb-4 mui-overide mui-select-override">
                                                    <FormControl fullWidth className='b-0 '>
                                                        <InputLabel id="demo-simple-select-label1" className='material-ui-select '>Skill Category</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label1"
                                                            id="demo-simple-select1"
                                                            label="Skill Category"
                                                            className=''
                                                            value={formData?.EmployementType == "null" ? "" : formData?.EmployementType}
                                                            variant="outlined"
                                                            name="EmployementType"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value="full time">Full Time</MenuItem>
                                                            <MenuItem value="part time">Part Time</MenuItem>
                                                            <MenuItem value="contract">Contract </MenuItem>
                                                            <MenuItem value="temporary">Temporary </MenuItem>
                                                        </Select>
                                                        <div className='icon-background' > <FontAwesomeIcon icon={faRectangleList} className='user-input-icon' /></div>
                                                    </FormControl>
                                                </div> */}

                                            </div>

                                            <div className="did-floating-label-content col-xl-11 col-lg-10 col-sm-12" style={{ position: "relative" }} id="autocomplete" >
                                                <input className="did-floating-input first-upper-cus" autocomplete="off" onChange={handleChangeSelect} value={value} name="value"  type="text" placeholder=" " ref={inputRef} />
                                                <div className='icon-background' > <FontAwesomeIcon icon={faRectangleList} className='user-input-icon' /></div>
                                                <span className='iconchange'></span>
                                                <label className="did-floating-label">Skill Category</label>
                                                {/* {value && ( */}
                                                
                                                {/* <FontAwesomeIcon
                                                    icon={faCaretDown}
                                                    
                                                    className={`${errorCategory ? "select-category-dropdown-err" : "select-category-dropdown"} select-category-dropdown`}
                                                /> */}
                                                {/* )} */}
                                                
                                                {suggestions.length > 0 && value && showDropdown && (
                                                    <ul
                                                        className='select-dropdown-ul'
                                                    >
                                                        {suggestions?.map((suggestion, index) => (
                                                            <li className='select-dropdown-li' key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                                {suggestion?.CategoryName}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <p className="mb-1 form-error form-error">{errorCategory}</p>
                                                {/* <p className="mb-1 form-error form-error">{formErrors.FirstName}</p> */}
                                            </div>
                                            <div className='add-questionn-cus'>

                                                {/* <div><FaRegCommentAlt /></div> */}
                                                <div className="did-floating-label-content   col-lg-10 col-xl-11 col-sm-12 inpt-question-mob" >
                                                    <input className="did-floating-input  " onChange={handleChange} value={formData.Questions} autocomplete="off" name="Questions" type="text" placeholder=" " />
                                                    <div className='icon-background' > <FaRegCommentAlt className='user-input-icon' /></div>
                                                    <span className='iconchange'></span>
                                                    <label className="did-floating-label">Enter a question</label>
                                                    <p className="mb-1 form-error form-error">{formErrors.Questions}</p>
                                                </div>
                                                <div className="search-reset-btn col-xl-1  col-lg-2 col-sm-12  mt-1">

                                                    {id ?
                                                        <button className='search-btn-que reset' disabled={loading} onClick={handleUpdate}>
                                                            {loading ?
                                                                <>
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    <span class="sr-only">Loading...</span>
                                                                </> : null
                                                            } Update </button>
                                                        :
                                                        <button className='search-btn-que reset' onClick={handleSubmit} disabled={loading}>
                                                            {loading ?
                                                                <>
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                                                    </span>
                                                                    <span class="sr-only">Loading...</span>
                                                                </>
                                                                :
                                                                null
                                                            } Add </button>}


                                                </div>
                                            </div>


                                        </form>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div >

                    <div>
                        <Container fluid>
                            <Row>
                                <Col>

                                    <QuestionaryTable ref={childRef} reset={handleCancel} categoryData={categoryData} tableReload={tableReload} settableReload={settableReload} getCategory={getCategory} />
                                </Col>
                            </Row>
                        </Container>
                    </div>


                    <Footer />
                </div >
            </SideNavbar>
        </div>
    )
}

export default Questionary