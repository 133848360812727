import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../../assets/img/logo.png"
import AboutImg from '../../assets/img/about.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './LandingNavbar.css'
import { FaEnvelope, FaBuilding, FaChartPie, FaHeadset, FaKey, FaEyeSlash, FaEye } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { postLogin } from '../Services/api';
import { faArrowUpShortWide } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from '../Dashboard/ScrollToTop'
import { useDispatch } from 'react-redux'
import { setToken } from '../../app/slice/userAuthenticate'
import { toast } from 'react-toastify';

const Login = () => {
  let location = useLocation()
  let navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false);
  const [iconChange, setIconChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const iconToggle = () => {
    setIconChange(!iconChange);
    setPasswordShown(!passwordShown);
  }
  const dispatch = useDispatch();
  const init = {
    email: "",
    password: "",
  }

  const [formData, setData] = useState(init);
  const [formErrors, setFormErrors] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target
    setData(prevForm => {
      return {
        ...prevForm,
        [name]: value
      }
    });

  }

  const validate = (values) => {
    const errors = {};
    const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setFormErrors(validate(formData));
    if (Object.keys(validate(formData)).length === 0) {
      setLoading(true);
      postLogin('api/Authentication/Login', formData).then((res) => {
        // if(res.data.status != "200"){
        //   toast.error(res.data.message);
        //   console.log(res.data.status)
        //   return;
        // }
        dispatch(setToken(res?.data?.data));
        if (res?.data?.data?.token) {

          navigate('/', { replace: true })
          // if(location.state?.from){
          //   navigate(location.state.from)
          // }
          let token = JSON.stringify(res?.data?.data)
          localStorage.setItem("token", token)
          let authTrue = JSON.parse(localStorage.getItem('token'))
          sessionStorage.setItem('Data', token);
          setLoading(false);
        }
        else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      });
    }
  }
  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem('token'))
    if (isAuth && isAuth !== 'undefined') {
      return navigate('/', { replace: true })
    }
  }, [])
  useEffect(() => {

  }, [])
  const [expanded, setExpanded] = useState(false);
  const [navbarScroll, setLinks] = useState(false)

  const changeColor = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 60) {
      setLinks(true)
    } else {
      setLinks(false)
    }
  }
  // const [navbar, setNavbar] = useState(false)
  useEffect(() => {
    changeColor()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeColor)
  }, [navbarScroll])
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div>

      {/* navbar and banner section */}

      <div className='img-banner1'>
        <div className={`${navbarScroll ? 'sticky-header' : 'header-nav'} ${'nav-item'} ${'mob-fixed-bg'}`} id="header">
          <Navbar collapseOnSelect expand="lg" variant="dark" className='shadow-sm-mob' expanded={expanded}>
            <Container >
              <Navbar.Brand to="/" className='navlink-cus'><img src={Logo} /></Navbar.Brand>
              <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>

                </Nav>
                <Nav>
                  <Link to="/login" onClick={() => setExpanded(false)} className='navlink-cus'  ><span onClick={scrollTop}>Home</span></Link>


                  <a href='#aboutus' onClick={() => setExpanded(false)} className='navlink-cus'>About Us</a>
                  <a href="#howitswork" onClick={() => setExpanded(false)} className=' navlink-cus'>How It Works</a>

                  <Link to="/Signup" className='navlink-cus'>
                    <button className='btn-register'>Register</button>
                  </Link>




                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>


        </div>
        <div className='mobresponsive-height'>


          <div className='landingpage-banner'>
            <Container>
              <Row>
                <Col lg={5} sm={12}>
                  <div className='banner-content'>
                    <h1>
                      Let's make a
                      <br />
                      <span> trustworthy  </span> hiring!
                    </h1>
                    <p>
                      Worried of candidates slipping away with another offer? Let's make your recruitment a cup of tea! Our projectR lets the other employers know that you have chosen the profile and shared an offer or onboarded the candidate.
                    </p>
                  </div>
                </Col>
                <Col lg={1} sm={12}></Col>
                <Col lg={1} sm={12}></Col>
                <Col lg={5} sm={12} className='login-form login-box-css p-3'>
                  <div className=''>
                    <div className='form-box-content'>
                      <h4 className='text-center'>Welcome Back!</h4>
                      <h6 className='text-center'>Login to continue to projectR</h6>
                      <form autocomplete="off" >
                        <div className='search-profile-form'>
                          <div className="did-floating-label-content  col-lg-12 col-sm-12 mt-2 mb-3"  >
                            <input className="did-floating-input" autocomplete="off" onChange={handleChange} value={formData.email} name="email" maxLength="75" type="text" placeholder=" " />
                            <div className='icon-background' > <FaEnvelope className='user-input-icon' /></div>
                            <span className='iconchange'></span>
                            <label className="did-floating-label">Email *</label>
                            <p className="mb-1 form-error">{formErrors.email}</p>
                          </div>
                          <div className="did-floating-label-content  col-lg-12 col-sm-12 mt-2 mb-3" >
                            <input className="did-floating-input" autocomplete="off" onChange={handleChange} value={formData.password} maxLength="20" name="password" type={passwordShown ? "text" : "password"} placeholder=" " />
                            <div className='icon-background '> <FaKey className='user-input-icon' /></div>
                            {iconChange ? <div className='icon-login-mob'> <FaEyeSlash className='user-input-icon-eye' onClick={iconToggle} /> </div> : <div className='icon-login-mob'><FaEye className='user-input-icon-eye' onClick={iconToggle} /></div>}
                            <span className='iconchange'></span>
                            <label className="did-floating-label">Password *</label>
                            <p className="mb-1 form-error">{formErrors.password}</p>
                          </div>
                          <button className='btn-login mt-2 mb-3' onClick={handleLogin}>{loading ? <><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span></> : null}&nbsp;Login</button>
                        </div>
                      </form>
                      <p className='text-center mt-2 mb-3'><Link to="/forgotpassword" style={{ textDecoration: "none", color: "#E0701A" }}>Forgot password?</Link></p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      {/* about us section */}

      <div>
        <Container id="aboutus" className="mt-1 mt-cus-abt" >
          <Row>
            <Col lg={12} sm={12} className="mb-4">
              <div>

                <div className='about-content' >
                  <h1 className='text-center mb-3' >
                    About Us
                  </h1>
                </div>


              </div>

            </Col>
            <Col lg={6} sm={12}>
              <img
                src={AboutImg}
                className="img-about"
              />
            </Col>
            <Col lg={6} sm={12} className="about-content">
              <h3>projectR  <span>Overview</span> </h3>
              <p>
                projectR is a genuine App that lets the employers have a stress-free and trustworthy recruiting. Share the profile of the candidate once offer shared or onboarded.
                Search the profile of the candidate who you are willing to check on.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="howitswork">

        <div className='about-content mt-5 pt-5'>
          <h1 className='text-center ' >
            How It Works
          </h1>
        </div>


      </div>

      <div className='mt-5 mb-5'>
        <Container>
          <Row>
            <Col lg={3} sm={12}>
              <div className='howitworks-box-css pb-5'>
                <div className='icon-box-home mt-5'>
                  <FaChartPie className='icon-howitsworks' />
                </div>
                <h1 className='mt-4'>Trusted Site</h1>
                <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt</p>
              </div>
            </Col>
            <Col lg={3} sm={12}>
              <div className='howitworks-box-css pb-5'>
                <div className='icon-box-home mt-5'>
                  <FaBuilding className='icon-howitsworks' />
                </div>
                <h1 className='mt-4'>Top Companies</h1>
                <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt</p>
              </div>
            </Col>
            <Col lg={3} sm={12}>
              <div className='howitworks-box-css pb-5'>
                <div className='icon-box-home mt-5'>
                  <FontAwesomeIcon icon={faArrowUpShortWide} className='icon-howitsworks' />
                </div>
                <h1 className='mt-4'>No Extra Charges</h1>
                <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt</p>
              </div>
            </Col>
            <Col lg={3} sm={12}>
              <div className='howitworks-box-css pb-5'>
                <div className='icon-box-home mt-5'>
                  <FaHeadset className='icon-howitsworks' />
                </div>
                <h1 className='mt-4'>24/7 Customer  Support</h1>
                <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='bg-footer-landing pt-5  pb-4'>
        <Container className='d-flex justify-content-center '>
          <Row>
            <Col>

              <div className='footer-copyright'>
                <p>Copyright © 2022 projectR. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Login