import React, { useState } from "react";
import "./Accordion.css";

const Accordion = ({ sections }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="accordion">
      {sections.map((section, index) => (
        <div className="accordion-item" key={index}>
          <div className="accordion-header title-interview-docs" onClick={() => handleToggle(index)}>
            <h4>{section.title}</h4>
            <span className={`icon-accordion ${openIndex === index ? "open" : ""}`} />
          </div>
          {openIndex === index && <div className="accordion-content">{section.content}</div>}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
