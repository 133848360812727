import React from 'react'
import FooterInterView from './FooterInterView'
import offline from '../../../assets/img/offline.png'
const OfflineStatus = () => {
    return (
        <div>
            <div className='thankyou-h-100'>
                <div className='thank__page__ui'>
                    <div className='box-of-thankyoupage'>
                        <img src={offline} className='img-icon-thankyou' />
                        <h1 >Network issue </h1>
                        <p >kindly close the tab and click again the interview link</p>
                    </div>

                </div>
            </div>

            <FooterInterView />
        </div>

    )
}

export default OfflineStatus